/* eslint-disable func-names */
import { takeLatest, put, call, select, join, fork, spawn, takeEvery, all } from 'redux-saga/effects';
import ClapiApi from '../api/clapi';
import RapidSearchConst from '../constants/rapidSearch';
import * as TYPE from '../actions/clapi';
import * as RapidAction from '../actions/rapid';
import {
    addCruxCmptError,
    CRUX_APP_ERROR,
    cruxAppError,
    invalidateCruxAppError,
    rmCruxCmptError,
} from '../actions/errorHandler';
import Bugsnag from '../bugsnag';
import RapidLocalisationHelper from '../helpers/search/RapidLocalisationHelper';
import Commons from '../helpers/Commons';
import { getPropertyByID } from './rapid';
import ErrorMsg from '../constants/errorMsg';
import { ErrorType } from '../constants/redirect';
import getSearchFiltersLimit from '../selectors/getSearchFiltersLimit';
import { getSavedListInfoMultiple } from '../actions/watchList';

export const getTargetProperty = store => store.rapid.get('targetProperty').value;

// GET SEARCH RESULT
function getLocationSearchResultsFn() {
    return function* (successType = TYPE.GET_SEARCH_RESULTS_SUCCESS,
                      failType = TYPE.GET_SEARCH_RESULTS_FAIL,
                      propertyId) {
        try {
            const state = yield select();
            let searchResults;
            const searchFilters = state.searchResults.get('searchFilters');
            const offset = state.searchResults.get('pageLimitOffset');
            const { lat, lon, suburbOnly } = searchFilters || {};

            let localityParams = {};
            if (lat && lon) {
                const prevTargetProperty = getTargetProperty(state);
                let targetPropertyTask;
                if (!prevTargetProperty) {
                    yield put({
                        type: RapidAction.GET_PROPERTY_BY_ID_FORKED,
                    });
                    targetPropertyTask = yield fork(
                        getPropertyByID,
                        {
                            payload: {
                                propertyId,
                            },
                        },
                    );
                    if (suburbOnly) {
                        if (targetPropertyTask) {
                            yield join(targetPropertyTask);
                        }
                        const targetPropertyDetails = yield select(getTargetProperty);
                        localityParams = RapidLocalisationHelper
                            .getRapidLocalityParamObj(targetPropertyDetails);
                    }
                }
            }
            const limit = getSearchFiltersLimit(state);
            searchResults = yield call(
                ClapiApi.getBatchSearch,
                {
                    ...searchFilters,
                    ...localityParams,
                    ...(suburbOnly ? { suburbOnly: null } : {}),
                    ...(limit ? { limit } : {}),
                    ...(offset ? { offset } : {}),
                },
            );
            yield put(TYPE.clearPageLimitOffset());

            // For radius search: Removing target property that is included in the return,
            // it should not display in results table.
            if (propertyId && searchResults.data) {
                const {
                    metadata,
                    data,
                } = searchResults || {};

                const prevSearchResults = state.searchResults.get('searchResults');
                const indexOfTargetProperty = data
                    .findIndex(list => list.id === parseInt(propertyId, 10));
                // Removing target property
                if (indexOfTargetProperty !== null && indexOfTargetProperty > -1) {
                    data.splice(indexOfTargetProperty, 1);
                    // subtracting target property count to totalElements count
                    metadata.totalElements -= 1;
                } else if (metadata.offset > 0 && prevSearchResults && prevSearchResults.metadata) {
                    // equating the previous total element less the target property.
                    // Showing more search results will update the total element count to its
                    // true value with the target property count.
                    // Should be implemented on pages other that 0 only.
                    metadata.totalElements = prevSearchResults.metadata.totalElements;
                }
                // after target property is removed and data length is empty,
                // that means radius inputted is too certain that the target
                // property is the only returned property.
                // Making searchResults into empty object will display in UI a no result field.
                if (data.length === 0) {
                    searchResults = {};
                }
            }

            // Call propertySummary when data is available
            if (searchResults.data) {
                const propertyIds = searchResults.data.map(({ id }) => id);
                yield put(
                    // eslint-disable-next-line no-use-before-define
                    TYPE.getPropertyDetailsByIds(
                        propertyIds,
                        RapidSearchConst.SUMMARY_RETURN_FIELDS,
                    ),
                );

                if (propertyIds?.length) {
                    // This will not proceed if not entitled
                    // Drill down the saga implementation to see the logic behind.
                    yield put(getSavedListInfoMultiple(propertyIds));
                }
            }

            yield put({
                type: successType,
                payload: searchResults,
            });

            yield put(invalidateCruxAppError());
        } catch (error) {
            if (error.status === 404) {
                yield put({
                    type: successType,
                    payload: {},
                });
            } else {
                Bugsnag.notify(error);
                yield put(cruxAppError(ErrorMsg.UNAVAILABLE, ErrorType.PAGE_ERROR));
                yield put({ type: failType });
            }
        }
    };
}
export const getLocationSearchResults = getLocationSearchResultsFn();
export function* getLocationSearchResultsWatcher() {
    yield takeLatest(TYPE.GET_SEARCH_RESULTS, getLocationSearchResults);
}

function getRapidSearchResultFn() {
    return function* (successType = TYPE.GET_RAPID_NAME_SEARCH_RESULT_SUCCESS,
                      failType = TYPE.GET_RAPID_NAME_SEARCH_RESULT_FAIL) {
        try {
            // select ids from state
            const state = yield select();
            const searchFilters = state.searchResults.get('searchFilters');
            const offset = state.searchResults.get('pageLimitOffset');
            const limit = getSearchFiltersLimit(state);
            const response = yield call(
                ClapiApi.getBatchSearch,
                {
                    ...searchFilters,
                    ...(limit ? { limit } : {}),
                    ...(offset ? { offset } : {}),
                },
            );
            yield put(TYPE.clearPageLimitOffset());

            // Call propertySummary when data is available
            if (Commons.get(response, 'data.length')) {
                const propertyIds = response.data.map(({ id }) => id);
                yield put(
                    // eslint-disable-next-line no-use-before-define
                    TYPE.getPropertyDetailsByIds(
                        propertyIds,
                        RapidSearchConst.SUMMARY_RETURN_FIELDS,
                    ),
                );

                if (propertyIds?.length) {
                    // This will not proceed if not entitled
                    // Drill down the saga implementation to see the logic behind.
                    yield put(getSavedListInfoMultiple(propertyIds));
                }
            }

            yield put({
                type: successType,
                payload: response,
            });
            yield put(invalidateCruxAppError());
        } catch (error) {
            Bugsnag.notify(error);
            yield put(cruxAppError(ErrorMsg.UNAVAILABLE, ErrorType.PAGE_ERROR));
            yield put({ type: failType });
        }
    };
}
export const getRapidSearchResult = getRapidSearchResultFn();
export function* getRapidSearchResultWatcher() {
    yield takeLatest(
        TYPE.GET_RAPID_NAME_SEARCH_RESULT,
        getRapidSearchResult,
        TYPE.GET_RAPID_NAME_SEARCH_RESULT_SUCCESS,
        TYPE.GET_RAPID_NAME_SEARCH_RESULT_FAIL,
    );
}

// SET SEARCH FILTERS V2
function setSearchFiltersV2Fn() {
    return function* (successType,
                      failType,
                      action) {
        try {
            if (action.payload.isNonAddressSearch) {
                yield spawn(getRapidSearchResult, successType, failType);
            } else {
                yield spawn(getLocationSearchResults, successType, failType, action.payload.propertyId);
            }
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: CRUX_APP_ERROR, error });
        }
    };
}
export const setSearchFiltersV2 = setSearchFiltersV2Fn();
export function* setSearchFiltersV2Watcher() {
    yield takeLatest(TYPE.SET_SEARCH_FILTERS_V2, setSearchFiltersV2, undefined, undefined);
}

export function* setSearchFilterLimitWatcher() {
    yield takeLatest(TYPE.UPDATE_SEARCH_FILTERS_LIMIT, setSearchFiltersV2, undefined, undefined);
}

export function* updateSearchFiltersOffsetWatcher() {
    yield takeLatest(
        TYPE.UPDATE_SEARCH_FILTERS_OFFSET,
        setSearchFiltersV2,
        TYPE.SHOW_MORE_SUCCESS,
        undefined,
    );
}

// GET PROPERTY DETAILS BY PROPERTY ID
function getPropertyDetailsByIdsFn() {
    return function* (successType, failType, action) {
        try {
            if (action.payload.length !== 0) {
                const ids = [];
                for (let i = 0; i < action.payload.length; i += 50) {
                    ids.push(action.payload.slice(i, i + 50));
                }
                const results = yield all(ids.map((_ids) => call(
                    ClapiApi.getPropertyDetailsByIds,
                    _ids,
                    action.returnFields,
                )));

                const searchResults = results.reduce((responses, response) => {
                    if (response?.result?.propertySummaryList) {
                        responses.result.propertySummaryList.push(...response.result.propertySummaryList);
                    }
                    return responses;
                }, { result: { propertySummaryList: [] } });

                yield put({
                    type: successType,
                    payload: searchResults.result,
                });
                yield put(rmCruxCmptError(failType));
            } else {
                yield put(addCruxCmptError(
                    failType,
                    { noResults: true },
                ));
            }
        } catch (error) {
            Bugsnag.notify(error);
            // address search sale details fetching failed
            const failedPropertyIds = [];
            action.payload.forEach((id) => {
                failedPropertyIds.push({
                    propertyId: id,
                    saleDetailsFetchingFailed: true,
                    errors: [new Error('error')], // this will serve as placeholder for errors
                });
            });
            yield put({
                type: failType,
                payload: failedPropertyIds,
            });
        }
    };
}
export const getPropertyDetailsByIds = getPropertyDetailsByIdsFn();
export function* getPropertyDetailsByIdsWatcher() {
    yield takeEvery(
        TYPE.GET_PROPERTY_DETAILS_BY_IDS,
        getPropertyDetailsByIds,
        TYPE.GET_PROPERTY_DETAILS_BY_IDS_SUCCESS,
        TYPE.GET_PROPERTY_DETAILS_BY_IDS_FAIL,
    );
}

export default [
    getPropertyDetailsByIdsWatcher(),
    setSearchFiltersV2Watcher(),
    setSearchFilterLimitWatcher(),
    getLocationSearchResultsWatcher(),
    updateSearchFiltersOffsetWatcher(),
    getRapidSearchResultWatcher(),
];
