export const PRICE_PATTERN = /^([$]?([0-9]+)(,[0-9]+)*(\.[0-9]+)?)(K|k|M|m|B|b)?$/g;
export const PRICE_NUMBERS_ONLY_PATTERN = /^((\d+)(,\d+)*(\.\d+)?)?$/g;
export const LAND_SIZE_PATTERN = /^(([0-9]+)(,[0-9]{3})*(\.[0-9]+)?)?$/g;
export const FLOOR_AREA_PATTERN = /^(([0-9]+)(,[0-9]+)*(\.[0-9]+)?)(M2|m2|m²)?$/g;
export const PRICE_AND_LAND_SIZE_PATTERN = /^(^([$]?)([0-9,]+(\.[0-9]+)?)(K|k|M|m|B|b|M2|m2|m²|ha|HA|Ha|hA|\+)?)?$/g;
export const YEAR_BUILT_PATTERN = /^\d{4}$/;
export const PURE_NUMBER_PATTERN = /^\d+$/;
export const DISTANCE_PATTERN = /^(^([0-9]+(\.[0-9]+)?)(km|Km|kM|KM)?)?$/g;
export const METER_DISTANCE_PATTERN = /^(^([0-9]+(\.[0-9]+)?)(m|M)?)?$/g;
export const ACCEPTABLE_CHARS = /^[A-Za-z0-9._%'\-+@ ;,:]+$/;
export const SAVE_SEARCH_ACCEPTABLE_CHARS = /^[A-Za-z0-9.'\- ;,:]+$/;
export const AMPERSAND = /&/g;
export const LINK_PATTERN = /<a href="\/\/(.*?)">(.*?)<\/a>/g;
export const NOT_DIGIT = /\D/g;
export const NOT_DIGIT_WITH_DECIMAL = /[^0-9.]+/g;

export default {};
