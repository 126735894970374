import { takeLatest, put, select } from 'redux-saga/effects';
import * as TYPE from '../actions/segment';
import { CRUX_APP_ERROR } from '../actions/errorHandler';
import AnalyticsGateway from '../lib/AnalyticsGateway';
import Bugsnag from '../bugsnag';
import Commons from '../helpers/Commons';

const getMixpanelUser = (claudUser, clapiUser) => {
    const mixPanelUser = {
        userName: claudUser.userName,
        userId:  '',
        firstName: '',
        lastName: '',
        workEmail: '',
        tradeName: '',
        accountId: '',
        franchiseId: '',
        industrySegment: '',
    };

    if (clapiUser) {
        mixPanelUser.firstName = clapiUser.firstName || '';
        mixPanelUser.lastName = clapiUser.lastName || '';
        mixPanelUser.workEmail = clapiUser.emailAddress || '';
        mixPanelUser.userId = clapiUser.clUsrGuid || '';

        if (clapiUser.accountDetail) {
            mixPanelUser.tradeName = clapiUser.accountDetail.tradeName || '';
            mixPanelUser.accountId = clapiUser.accountDetail.externalAccountId || '';
            mixPanelUser.franchiseId = clapiUser.accountDetail.franchiseId || '';
            mixPanelUser.industrySegment = clapiUser.accountDetail.industrySegment || '';
        }
    }
    return mixPanelUser;
};

const addResultsView = activeView => activeView
    ? [{ label: 'Result View', value: activeView }]
    : [];

function initializeSegmentFn() {
    return function* () {
        try {
            const state = yield select();
            const segment = state.config.get('segment');
            const claudUser = state.claud.get('user');
            const clapiUser = state.clapi.get('usrDetail');
            const mixPanelUser = getMixpanelUser(claudUser, clapiUser);

            AnalyticsGateway.initialize({ segment });
            AnalyticsGateway.identifyUser(mixPanelUser);
            const action = { type: TYPE.SEGMENT_INIT_SUCCESS };
            yield put(action);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: CRUX_APP_ERROR, error });
            yield put({ type: TYPE.SEGMENT_INIT_FAIL });
        }
    };
}

function updateSegmentUserFn() {
    return function* () {
        try {
            const state = yield select();
            const claudUser = state.claud.get('user');
            const clapiUser = state.clapi.get('usrDetail');
            const mixPanelUser = getMixpanelUser(claudUser, clapiUser);

            AnalyticsGateway.identifyUser(mixPanelUser);
            const action = { type: TYPE.SEGMENT_UPDATE_USER_SUCCESS };
            yield put(action);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: CRUX_APP_ERROR, error });
            yield put({ type: TYPE.SEGMENT_UPDATE_USER_FAIL });
        }
    };
}

// -------------- Event Tracker for Suggestion
function createEventSuggestionFn() {
    return function* (action) {
        const { payload } = action;
        try {
            const isAddressSearch = payload.searchType === 'Address';
            const eventDetails = {
                eventName: 'Property Search',
                properties: [
                    { label: 'Page', value: action.pageContext },
                    { label: 'Entry Point', value: payload.entryPoint },
                    { label: 'Search Context', value: payload.searchContext },
                    { label: 'Search String', value: payload.searchString },
                    { label: 'Search Type', value: payload.searchType },
                    { label: 'Time Frame', value: payload.timeFrame },
                    {
                        label: 'Include Historic',
                        value: isAddressSearch && `${payload.historic}` },
                    {
                        label: 'Multi Locality',
                        value: isAddressSearch && `${payload.searchString?.length > 1}`,
                    },
                    { label: 'Search Tab', value: payload.searchTab },
                    ...addResultsView(payload.activeView),
                ],
            };

            eventDetails.properties = eventDetails.properties.filter(property => property.value);

            AnalyticsGateway.sendEvent(eventDetails);
            yield put({ type: TYPE.EVENT_TRACKER_SUGGESTIONS_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_TRACKER_SUGGESTIONS_FAIL });
        }
    };
}

// -------------- Event Tracker for LOGO
function viewHomeEvent() {
    return function* (action) {
        const { event, route } = action.payload;
        try {
            AnalyticsGateway.sendEvent({
                eventName: event,
                properties: [
                    { label: 'Page', value: route.pageContext },
                ],
            });
            yield put({ type: TYPE.EVENT_VIEW_HOME_SUCCESS });
        } catch (error) {
            yield put({ type: TYPE.EVENT_VIEW_HOME_FAIL });
        }
    };
}

// -------------- Event Tracker for Image Navigation
function navigateImagesFn() {
    return function* (action) {
        const { payload, panel, pageContext, objectContext } = action;
        try {
            AnalyticsGateway.sendEvent({
                eventName: 'Navigate Carousel',
                properties: [
                    { label: 'Page', value: pageContext },
                    { label: 'Property ID', value: payload },
                    { label: 'Panel', value: panel },
                    { label: 'Object Context', value: objectContext },
                ],
            });
            yield put({ type: TYPE.EVENT_TRACKER_IMAGE_CHANGE_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_TRACKER_IMAGE_CHANGE_FAIL });
        }
    };
}

// -------------- Event Tracker for Image Navigation
function imageFullScreenFn() {
    return function* (action) {
        const { payload } = action;
        try {
            AnalyticsGateway.sendEvent({
                eventName: 'View Image Gallery',
                properties: [
                    { label: 'Page', value: 'Property Detail' },
                    { label: 'Property ID', value: payload },
                ],
            });
            yield put({ type: TYPE.EVENT_TRACKER_IMAGE_FULLSCREEN_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_TRACKER_IMAGE_FULLSCREEN_FAIL });
        }
    };
}

// -------------- Event Tracker for BreadCrumbs
function navigateViaBreadCrumb() {
    return function* (action) {
        const { payload } = action;
        let eventName = 'View Home';
        const properties = [
            { label: 'Page', value: 'Property Detail' },
            { label: 'Entry Point', value: 'Breadcrumb' },
            ...addResultsView(payload.activeView),
        ];

        if (payload.searchContext && payload.searchContext.toUpperCase() !== 'HOME') {
            eventName = 'Property Search';
            properties.push({ label: 'Search Context', value: payload.searchContext });
            properties.push({ label: 'Search String', value: payload.searchString });
            properties.push({ label: 'Search Type', value: 'Address' });
        }

        try {
            AnalyticsGateway.sendEvent({ eventName, properties });
            yield put({ type: TYPE.EVENT_TRACKER_BREADCRUMB_NAVIGATION_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_TRACKER_BREADCRUMB_NAVIGATION_FAIL });
        }
    };
}

// -------------- Event Tracker for LOGO
function sendClickCopyIconEventFn() {
    return function* (action) {
        const { payload, pageContext } = action;
        try {
            AnalyticsGateway.sendEvent({
                eventName: 'Copy Property Address',
                properties: [
                    { label: 'Page', value: pageContext },
                    { label: 'Property ID', value: payload },
                ],
            });
            yield put({ type: TYPE.EVENT_TRACKER_COPY_ICON_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_TRACKER_COPY_ICON_FAIL });
        }
    };
}
// -------------- Event Tracker for Scroll to Floor Plan
function scrollToFloorPlanEventFn() {
    return function* (action) {
        const { payload, pageContext } = action;
        try {
            AnalyticsGateway.sendEvent({
                eventName: 'Scroll to Floor Plan',
                properties: [
                    { label: 'Page', value: pageContext },
                    { label: 'Property ID', value: payload.propertyId },
                    { label: 'Panel', value: payload.panelSource },
                ],
            });
            yield put({ type: TYPE.EVENT_TRACKER_SCROLL_FLOOR_PLAN_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_TRACKER_SCROLL_FLOOR_PLAN_FAIL });
        }
    };
}
// -------------- Event Tracker for Download Report
function sendDownloadReportEventFn() {
    return function* (action) {
        const {
            payload, pageContext, reportFormat, reportType, preparedByChecked, entryPoint,
        } = action;

        try {
            const properties = [
                { label: 'Page', value: pageContext },
                { label: 'Report Format', value: reportFormat },
                { label: 'Property ID', value: payload },
                { label: 'Report Type', value: reportType },
                { label: 'Report Option Owner Name', value: preparedByChecked },
            ];
            if (entryPoint) {
                properties.push({ label: 'Entry Point', value: entryPoint });
            }
            AnalyticsGateway.sendEvent({
                eventName: 'Download Report',
                properties,
            });
            yield put({ type: TYPE.EVENT_TRACKER_SEND_DOWNLOAD_REPORT_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_TRACKER_SEND_DOWNLOAD_REPORT_FAIL });
        }
    };
}
// -------------- Event Tracker for Download Report
function sendPrintReportEventFn() {
    return function* (action) {
        const {
            payload, pageContext, reportType, preparedByChecked, entryPoint,
        } = action;

        try {
            const properties = [
                { label: 'Page', value: pageContext },
                { label: 'Property ID', value: payload },
                { label: 'Report Type', value: reportType },
                { label: 'Report Option Owner Name', value: preparedByChecked },
            ];
            if (entryPoint) {
                properties.push({ label: 'Entry Point', value: entryPoint });
            }
            AnalyticsGateway.sendEvent({
                eventName: 'Print PDF Report',
                properties,
            });
            yield put({ type: TYPE.EVENT_TRACKER_SEND_PRINT_REPORT_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_TRACKER_SEND_PRINT_REPORT_FAIL });
        }
    };
}

// -------------- Event Tracker for RPP Navigate from CRUX
function rppNavigationEventFn() {
    return function* (action) {
        try {
            const properties = [
                { label: 'Page', value: action.pageContext },
            ];
            if (action.payload) {
                properties.push({ label: 'Property ID', value: action.payload });
            }
            AnalyticsGateway.sendEvent({
                eventName: 'Launch RPP',
                properties,
            });
            yield put({ type: TYPE.EVENT_NAVIGATE_TO_RPP_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_NAVIGATE_TO_RPP_FAIL });
        }
    };
}

// -------------- Event Tracker for View Listing Description
function showMoreListingDescFn() {
    return function* (action) {
        try {
            const {
                propertyId, pageContext, panelType,
            } = action.payload;
            AnalyticsGateway.sendEvent({
                eventName: 'Show More',
                properties: [
                    { label: 'Page', value: pageContext },
                    { label: 'Property ID', value: propertyId },
                    { label: 'Panel', value: panelType },
                ],
            });
            yield put({ type: TYPE.SHOW_MORE_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.SHOW_MORE_FAIL });
        }
    };
}

// -------------- Event Tracker for View Listing Description
function viewListingDescEventFn() {
    return function* (action) {
        try {
            const {
                payload, pageContext, panelType,
            } = action;
            AnalyticsGateway.sendEvent({
                eventName: 'View Listing Description',
                properties: [
                    { label: 'Page', value: pageContext },
                    { label: 'Property ID', value: payload },
                    { label: 'Panel', value: panelType },
                ],
            });
            yield put({ type: TYPE.EVENT_VIEW_LISTING_DESCRIPTION_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_VIEW_LISTING_DESCRIPTION_FAIL });
        }
    };
}

// -------------- Event Tracker for View Similar Properties
function viewSimilarPropertiesEventFn() {
    return function* (action) {
        try {
            const {
                payload, pageContext, panelType, tab,
            } = action;
            AnalyticsGateway.sendEvent({
                eventName: 'View Similar Properties',
                properties: [
                    { label: 'Page', value: pageContext },
                    { label: 'Property ID', value: payload },
                    { label: 'Panel', value: panelType },
                    { label: 'Tab', value: tab },
                ],
            });
            yield put({ type: TYPE.EVENT_VIEW_SIMILAR_PROPERTIES_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_VIEW_SIMILAR_PROPERTIES_FAIL });
        }
    };
}

// -------------- Event Tracker for View Listing Description
function viewOnMapNearbySchoolsEventFn() {
    return function* (action) {
        try {
            const {
                payload, pageContext, panelType, tab,
            } = action;
            AnalyticsGateway.sendEvent({
                eventName: 'View Nearby Schools',
                properties: [
                    { label: 'Page', value: pageContext },
                    { label: 'Property ID', value: payload },
                    { label: 'Panel', value: panelType },
                    { label: 'Tab', value: tab },
                ],
            });
            yield put({ type: TYPE.EVENT_VIEW_ON_MAP_NEARBY_SCHOOLS_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_VIEW_ON_MAP_NEARBY_SCHOOLS_FAIL });
        }
    };
}

// -------------- Event Tracker for View Listing Description
function handleTabChangesEventFn() {
    return function* (action) {
        try {
            const {
                payload, pageContext, panelType, tab,
            } = action;
            AnalyticsGateway.sendEvent({
                eventName: 'Switch Tabs',
                properties: [
                    { label: 'Page', value: pageContext },
                    { label: 'Property ID', value: payload },
                    { label: 'Panel', value: panelType },
                    { label: 'Tab', value: tab },
                ],
            });
            yield put({ type: TYPE.EVENT_TAB_CHANGE_SIMILAR_PROPERTIES_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_TAB_CHANGE_SIMILAR_PROPERTIES_FAIL });
        }
    };
}

function trackApplyFilterEventFn() {
    return function* (action) {
        try {
            yield AnalyticsGateway.sendEvent({
                eventName: 'Refine Search',
                properties: action.payload.eventProps,
            });
            yield put({ type: TYPE.EVENT_TRACKER_APPLY_FILTER_SUCCESS });
        } catch (e) {
            Bugsnag.notify(e);
            yield put({ type: TYPE.EVENT_TRACKER_APPLY_FILTER_FAIL });
        }
    };
}

// -------------- Event Tracker for Nearby Schools Tab
function handleTabChangesNearbySchoolsEventFn() {
    return function* (action) {
        try {
            const {
                payload, pageContext, panelType, tab,
            } = action;
            AnalyticsGateway.sendEvent({
                eventName: 'Switch Tabs',
                properties: [
                    { label: 'Page', value: pageContext },
                    { label: 'Property ID', value: payload },
                    { label: 'Panel', value: panelType },
                    { label: 'Tab', value: tab },
                ],
            });
            yield put({ type: TYPE.EVENT_TAB_CHANGE_NEARBY_SCHOOLS_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_TAB_CHANGE_NEARBY_SCHOOLS_FAIL });
        }
    };
}
function trackMapModalOpenFn() {
    return function* (action) {
        try {
            const {
                payload, pageContext, panelType,
            } = action;
            AnalyticsGateway.sendEvent({
                eventName: 'View Expanded Map',
                properties: [
                    { label: 'Page', value: pageContext },
                    { label: 'Property ID', value: payload },
                    { label: 'Panel', value: panelType },
                ],
            });
            yield put({ type: TYPE.EVENT_MAP_MODAL_OPEN_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_MAP_MODAL_OPEN_FAIL });
        }
    };
}

// -------------- Event Mobile Badge Click
function mobileBadgeClickEventFn() {
    return function* (action) {
        try {
            const { payload } = action;
            AnalyticsGateway.sendEvent({
                eventName: 'Download App',
                properties: [
                    { label: 'Page', value: payload.pageContext },
                    { label: 'Object Context', value: payload.brand },
                ],
            });
            yield put({ type: TYPE.EVENT_MOBILE_BADGE_CLICK_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_MOBILE_BADGE_CLICK_FAIL });
        }
    };
}

function trackDefaultDashboardUpdateFn() {
    return function* (action) {
        try {
            const {
                payload, pageContext,
            } = action;
            AnalyticsGateway.sendEvent({
                eventName: 'Update RP Data Default',
                properties: [
                    { label: 'Page', value: pageContext },
                    { label: 'Object Context', value: payload },
                ],
            });
            yield put({ type: TYPE.EVENT_UPDATE_DEFAULT_DASHBOARD_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_UPDATE_DEFAULT_DASHBOARD_FAIL });
        }
    };
}

// -------------- Event Tracker for Name Search
function trackNameSearchFn() {
    return function* (action) {
        const { payload } = action;
        try {
            AnalyticsGateway.sendEvent({
                eventName: 'Property Search',
                properties: [
                    { label: 'Page', value: payload.pageContext },
                    { label: 'Search String', value: payload.searchString },
                    { label: 'Search Type', value: payload.searchType },
                    { label: 'Entry Point', value: payload.entryPoint },
                    { label: 'Current Owner', value: payload.currentOwner },
                    { label: 'Exact Match', value: payload.exactSearch },
                    ...addResultsView(payload.activeView),
                ],
            });
            yield put({ type: TYPE.EVENT_TRACKER_NAME_SEARCH_SUCCESS });
        } catch (error) {
            yield put({ type: TYPE.EVENT_TRACKER_NAME_SEARCH_FAIL });
            Bugsnag.notify(error);
        }
    };
}

// -------------- Event Tracker for Company Search
function trackCompanySearchFn() {
    return function* (action) {
        const { payload } = action;
        try {
            AnalyticsGateway.sendEvent({
                eventName: 'Property Search',
                properties: [
                    { label: 'Page', value: payload.pageContext },
                    { label: 'Search String', value: payload.searchString },
                    { label: 'Search Type', value: payload.searchType },
                    { label: 'Entry Point', value: payload.entryPoint },
                    { label: 'Current Owner', value: payload.currentOwner },
                    { label: 'Exact Match', value: payload.exactSearch },
                    ...addResultsView(payload.activeView),
                ],
            });
            yield put({ type: TYPE.EVENT_TRACKER_COMPANY_SEARCH_SUCCESS });
        } catch (error) {
            yield put({ type: TYPE.EVENT_TRACKER_COMPANY_SEARCH_FAIL });
            Bugsnag.notify(error);
        }
    };
}

// -------------- Event Tracker for Volume/Folio Search
function trackVolumeFolioSearchFn() {
    return function* (action) {
        const { payload } = action;
        try {
            AnalyticsGateway.sendEvent({
                eventName: 'Property Search',
                properties: [
                    { label: 'Page', value: payload.pageContext },
                    { label: 'Search String', value: payload.searchString },
                    { label: 'Search Type', value: payload.searchType },
                    { label: 'Entry Point', value: payload.entryPoint },
                    ...addResultsView(payload.activeView),
                ],
            });
            yield put({ type: TYPE.EVENT_TRACKER_VOLUME_FOLIO_SEARCH_SUCCESS });
        } catch (error) {
            yield put({ type: TYPE.EVENT_TRACKER_VOLUME_FOLIO_SEARCH_FAIL });
            Bugsnag.notify(error);
        }
    };
}

// -------------- Event Tracker for Intellival AVM Report
function trackIntellivalAvmReportFn() {
    return function* (action) {
        const { payload } = action;
        const properties = [
            { label: 'Page', value: payload.pageContext },
            { label: 'Property ID', value: payload.propertyId },
            { label: 'Report Type', value: payload.reportType },
            { label: 'Report Format', value: 'PDF' },
        ];

        if (payload.entryPoint) {
            properties.push({ label: 'Entry Point', value: payload.entryPoint });
        }

        try {
            AnalyticsGateway.sendEvent({
                eventName: 'Generate Report',
                properties,
            });
            yield put({ type: TYPE.EVENT_TRACKER_INTELLIVAL_REPORT_SUCCESS });
        } catch (error) {
            yield put({ type: TYPE.EVENT_TRACKER_INTELLIVAL_REPORT_FAIL });
            Bugsnag.notify(error);
        }
    };
}

function trackPropertyProfileReportFn() {
    return function* (action) {
        const { payload } = action;
        const properties = [
            { label: 'Page', value: payload.pageContext },
            { label: 'Property ID', value: payload.propertyId },
            { label: 'Report Type', value: payload.reportType },
            { label: 'Report Format', value: 'PDF' },
            { label: 'Report Option Include AVM', value:  Commons.get(payload, 'customParams.requesterInput.includeAVMEstimate') },
        ];

        if (payload.entryPoint) {
            properties.push({ label: 'Entry Point', value: payload.entryPoint });
        }

        try {
            AnalyticsGateway.sendEvent({
                eventName: 'Generate Report',
                properties,
            });
            yield put({ type: TYPE.EVENT_TRACK_PROPERTY_PROFILE_REPORT_SUCCESS });
        } catch (error) {
            yield put({ type: TYPE.EVENT_TRACK_PROPERTY_PROFILE_REPORT_FAIL });
            Bugsnag.notify(error);
        }
    };
}
/**
 * Mixpanel event for home button cliked on map
 * @returns {Function}
 */
function trackHomeOnMapFn() {
    return function* (action) {
        const { payload } = action;
        const properties = [
            { label: 'Page', value: payload.pageContext },
            { label: 'Property ID', value: payload.propertyId },
            { label: 'Panel', value: payload.panel },
        ];

        try {
            AnalyticsGateway.sendEvent({
                eventName: 'Home on Map',
                properties,
            });
            yield put({ type: TYPE.EVENT_TRACKER_HOME_ON_MAP_SUCCESS });
        } catch (error) {
            yield put({ type: TYPE.EVENT_TRACKER_HOME_ON_MAP_FAIL });
            Bugsnag.notify(error);
        }
    };
}

// Event tracker for displaying property bubble when clicking on map
function trackBubbleClickOnMapFn() {
    return function* (action) {
        const { payload } = action;
        const properties = [
            { label: 'Page', value: payload.pageContext },
            { label: 'Property ID', value: payload.propertyId },
            { label: 'Panel', value: payload.panel },
            { label: 'Object Context', value: payload.objectContext },
        ];

        try {
            AnalyticsGateway.sendEvent({
                eventName: 'View Property Bubble',
                properties,
            });
            yield put({ type: TYPE.EVENT_TRACKER_MAP_BUBBLE_CLICK_SUCCESS });
        } catch (error) {
            yield put({ type: TYPE.EVENT_TRACKER_MAP_BUBBLE_CLICK_FAIL });
            Bugsnag.notify(error);
        }
    };
}

function navigateToDepCalculatorEventFn() {
    return function* (action) {
        try {
            const { payload } = action;
            const properties = [
                { label: 'Property ID', value: payload.propertyId },
                { label: 'Panel', value: payload.panel },
                { label: 'Page', value: payload.page },
            ];
            AnalyticsGateway.sendEvent({
                eventName: 'Calculate Depreciation',
                properties,
            });
            yield put({ type: TYPE.EVENT_NAVIGATE_TO_DEP_CALCULATOR_SUCCESS });
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: TYPE.EVENT_NAVIGATE_TO_DEP_CALCULATOR_FAIL });
        }
    };
}

/**
 * Track Mixpanel event function (generic)
 * see source/js/helpers/Segment.js how to generate payload
 * @requires {payload: {eventName: '', properties: []}}
 * @returns {Function}
 */
function trackEventFn() {
    return function* (action) {
        const { payload } = action || {};

        try {
            const { eventName, properties } = payload || {};

            if (!eventName) {
                throw new Error('eventName is required');
            } else if (!properties || !Array.isArray(properties)) {
                throw new Error('properties is required and should be array');
            } else {
                AnalyticsGateway.sendEvent({ eventName, properties });
                yield put({ type: TYPE.EVENT_TRACKER_SUCCESS, eventName });
            }
        } catch (error) {
            yield put({ type: TYPE.EVENT_TRACKER_FAIL, payload, error });
            Bugsnag.notify(error);
        }
    };
}

export const initializeSegment = initializeSegmentFn();
export const updateSegmentUser = updateSegmentUserFn();
export const createEventSuggestion = createEventSuggestionFn();
export const sendClickLogoEvent = viewHomeEvent();
export const navigateImages = navigateImagesFn();
export const imageFullScreen = imageFullScreenFn();
export const navigateBreadCrumb = navigateViaBreadCrumb();
export const sendClickCopyIconEvent = sendClickCopyIconEventFn();
export const scrollToFloorPlanEvent = scrollToFloorPlanEventFn();
export const sendDownloadReportEvent = sendDownloadReportEventFn();
export const sendPrintReportEvent = sendPrintReportEventFn();
export const rppNavigationEvent = rppNavigationEventFn();
export const showMoreListingDesc = showMoreListingDescFn();
export const viewListingDescEvent = viewListingDescEventFn();
export const viewSimilarPropertiesEvent = viewSimilarPropertiesEventFn();
export const viewOnMapNearbySchoolsEvent = viewOnMapNearbySchoolsEventFn();
export const handleTabChangesEvent = handleTabChangesEventFn();
export const trackApplyFilterEvent = trackApplyFilterEventFn();
export const handleTabChangesNearbySchoolsEvent = handleTabChangesNearbySchoolsEventFn();
export const mobileBadgeClickEvent = mobileBadgeClickEventFn();
export const trackMapModalOpen = trackMapModalOpenFn();
export const trackDefaultDashboardUpdate = trackDefaultDashboardUpdateFn();
export const trackNameSearch = trackNameSearchFn();
export const trackCompanySearch = trackCompanySearchFn();
export const trackVolumeFolioSearch = trackVolumeFolioSearchFn();
export const trackIntellivalAvmReport = trackIntellivalAvmReportFn();
export const trackPropertyProfileReport = trackPropertyProfileReportFn();
export const trackHomeOnMap = trackHomeOnMapFn();
export const trackBubbleClickOnMap = trackBubbleClickOnMapFn();
export const trackEvent = trackEventFn();
export const navigateToDepCalculatorEvent = navigateToDepCalculatorEventFn();

export function* updateSegmentUserWatcher() {
    yield takeLatest(TYPE.SEGMENT_UPDATE_USER, updateSegmentUser);
}

export function* createEventSuggestionWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_SUGGESTIONS, createEventSuggestion);
}

export function* sendClickLogoEventWatcher() {
    yield takeLatest(TYPE.EVENT_VIEW_HOME, sendClickLogoEvent);
}

export function* navigateImagesWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_IMAGE_CHANGE, navigateImages);
}

export function* imageFullScreenWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_IMAGE_FULLSCREEN, imageFullScreen);
}

export function* navigateBreadCrumbWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_BREADCRUMB_NAVIGATION, navigateBreadCrumb);
}

export function* sendClickCopyIconEventWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_COPY_ICON, sendClickCopyIconEvent);
}

export function* scrollToFloorPlanEventWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_SCROLL_FLOOR_PLAN, scrollToFloorPlanEvent);
}

export function* sendDownloadReportEventWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_SEND_DOWNLOAD_REPORT, sendDownloadReportEvent);
}

export function* sendPrintReportEventWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_SEND_PRINT_REPORT, sendPrintReportEvent);
}

export function* rppNavigationEventWatcher() {
    yield takeLatest(TYPE.EVENT_NAVIGATE_TO_RPP, rppNavigationEvent);
}

export function* showMoreListingDescWatcher() {
    yield takeLatest(TYPE.SHOW_MORE, showMoreListingDesc);
}

export function* viewListingDescEventWatcher() {
    yield takeLatest(TYPE.EVENT_VIEW_LISTING_DESCRIPTION, viewListingDescEvent);
}

export function* viewSimilarPropertiesEventWatcher() {
    yield takeLatest(TYPE.EVENT_VIEW_SIMILAR_PROPERTIES, viewSimilarPropertiesEvent);
}

export function* viewOnMapNearbySchoolsEventWatcher() {
    yield takeLatest(TYPE.EVENT_VIEW_ON_MAP_NEARBY_SCHOOLS, viewOnMapNearbySchoolsEvent);
}

export function* handleTabChangesEventWatcher() {
    yield takeLatest(TYPE.EVENT_TAB_CHANGE_SIMILAR_PROPERTIES, handleTabChangesEvent);
}

export function* trackApplyFilterEventWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_APPLY_FILTER, trackApplyFilterEvent);
}

export function* handleTabChangesNearbySchoolsEventWatcher() {
    yield takeLatest(TYPE.EVENT_TAB_CHANGE_NEARBY_SCHOOLS, handleTabChangesNearbySchoolsEvent);
}

export function* mobileBadgeClickEventWatcher() {
    yield takeLatest(TYPE.EVENT_MOBILE_BADGE_CLICK, mobileBadgeClickEvent);
}

export function* trackMapModalOpenWatcher() {
    yield takeLatest(TYPE.EVENT_MAP_MODAL_OPEN, trackMapModalOpen);
}

export function* trackDefaultDashboardUpdateWatcher() {
    yield takeLatest(TYPE.EVENT_UPDATE_DEFAULT_DASHBOARD, trackDefaultDashboardUpdate);
}

export function* trackNameSearchWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_NAME_SEARCH, trackNameSearch);
}

export function* trackCompanySearchWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_COMPANY_SEARCH, trackCompanySearch);
}

export function* trackVolumeFolioSearchWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_VOLUME_FOLIO_SEARCH, trackVolumeFolioSearch);
}

export function* trackIntellivalAvmReportWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_INTELLIVAL_REPORT, trackIntellivalAvmReport);
}

export function* trackPropertyProfileReportWatcher() {
    yield takeLatest(TYPE.EVENT_TRACK_PROPERTY_PROFILE_REPORT, trackPropertyProfileReport);
}

export function* trackHomeOnMapWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_HOME_ON_MAP, trackHomeOnMap);
}

export function* trackBubbleClickOnMapWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER_MAP_BUBBLE_CLICK, trackBubbleClickOnMap);
}

export function* navigateToDepCalculatorEventWatcher() {
    yield takeLatest(TYPE.EVENT_NAVIGATE_TO_DEP_CALCULATOR, navigateToDepCalculatorEvent);
}

/**
 * Generic mixpanel event watcher
 * @returns {IterableIterator<*>}
 */
export function* trackEventWatcher() {
    yield takeLatest(TYPE.EVENT_TRACKER, trackEvent);
}

export default [
    createEventSuggestionWatcher(),
    sendClickLogoEventWatcher(),
    navigateImagesWatcher(),
    navigateBreadCrumbWatcher(),
    sendClickCopyIconEventWatcher(),
    scrollToFloorPlanEventWatcher(),
    sendDownloadReportEventWatcher(),
    sendPrintReportEventWatcher(),
    imageFullScreenWatcher(),
    rppNavigationEventWatcher(),
    viewListingDescEventWatcher(),
    showMoreListingDescWatcher(),
    viewSimilarPropertiesEventWatcher(),
    viewOnMapNearbySchoolsEventWatcher(),
    handleTabChangesEventWatcher(),
    trackApplyFilterEventWatcher(),
    handleTabChangesNearbySchoolsEventWatcher(),
    mobileBadgeClickEventWatcher(),
    trackMapModalOpenWatcher(),
    trackDefaultDashboardUpdateWatcher(),
    trackNameSearchWatcher(),
    trackCompanySearchWatcher(),
    trackVolumeFolioSearchWatcher(),
    trackIntellivalAvmReportWatcher(),
    trackPropertyProfileReportWatcher(),
    trackHomeOnMapWatcher(),
    trackBubbleClickOnMapWatcher(),
    trackEventWatcher(),
    navigateToDepCalculatorEventWatcher(),
    updateSegmentUserWatcher(),
];
