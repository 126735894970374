import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment/moment';
import Checkbox from '@mui/material/Checkbox';
import Colors from 'js/constants/colors';
import ReportForm from './ReportForm';
import ReportFormFields, { ReportDateField, ReportMUIPriceField }  from './ReportFormFields';
import { getPropertyInvestmentReport } from '../../actions/pdfReports';
import Reports from '../../constants/reports';
import commons from '../../helpers/Commons';
import ErrorMsg from '../../constants/errorMsg';
import ValidationMessage from '../../constants/validationMessage';
import PriceHelper from '../../helpers/PriceHelper';
import { NOT_DIGIT } from '../../constants/regexPatterns';

const ACKNOWLEDGE_AND_AGREE = 'I acknowledge and agree that I will first obtain my client\'s consent to receive this report before supplying them with the Property Investment Report.';
const PropertyInvestmentReport = (props) => {
    const dispatch = useDispatch();
    const [currentLeaseAmountPerWeek, setCurrentLeaseAmountPerWeek] = useState('');
    const [leaseStartDate, setLeaseStartDate] = useState(null);
    const [leaseEndDate, setLeaseEndDate] = useState(null);
    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState('');
    const [dateRangeError, setDateRangeError] = useState(false);
    const [acknowledge, setAcknowledge] = useState(false);
    const [leaseAmountError, setLeaseAmountError] = useState('');
    const isVIC = props.propertyState?.toLowerCase() === 'vic';
    const isDatesValid = () => {
        const startDate = moment(leaseStartDate);
        const endDate = moment(leaseEndDate);
        const hasStartDateOnly = leaseStartDate && !leaseEndDate;
        const hasEndDateOnly = leaseEndDate && !leaseStartDate;
        const rangeError = moment(endDate).isBefore(startDate, 'day');

        if(rangeError){
            setDateRangeError(true)
            setEndDateError(ErrorMsg.END_DATE_RANGE_ERROR);
            setStartDateError(ErrorMsg.START_DATE_RANGE_ERROR);
        } else if (hasStartDateOnly) {
            setEndDateError(ErrorMsg.MANDATORY_END_DATE);
        } else if (hasEndDateOnly) {
            setStartDateError(ErrorMsg.MANDATORY_START_DATE);
        }

        return !hasStartDateOnly && !hasEndDateOnly && !rangeError;
    };

    const isLeaseAmountValid = () => {
        const isValid = PriceHelper.validValue(currentLeaseAmountPerWeek);
        if (!isValid && !!currentLeaseAmountPerWeek) {
            setLeaseAmountError(ValidationMessage.PRICE_FORMAT_ERROR);
        } else if (leaseAmountError) {
            setLeaseAmountError('');
        }
        return isValid;
    };

    const generate = () => {
        const validDate = isDatesValid();
        const validAmount = isLeaseAmountValid();
        const valid = validDate && validAmount;
        if (valid) {
            dispatch(getPropertyInvestmentReport({
                propertyId: props.propertyId,
                leaseStartDate: leaseStartDate
                    ? commons.formatDate(leaseStartDate, 'YYYY-MM-DD')
                    : null,
                leaseEndDate: leaseEndDate
                    ? commons.formatDate(leaseEndDate, 'YYYY-MM-DD')
                    : null,
                letAmount: currentLeaseAmountPerWeek && currentLeaseAmountPerWeek !== '0'
                    && currentLeaseAmountPerWeek.replace(NOT_DIGIT, ''),
                entryPoint: props.entryPoint,
                pageContext: props.pageContext,
            }));
        }
        return valid;
    };

    const handleResetForm = () => {
        props.toggleReportModal();
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    useEffect(() => {
        if (dateRangeError || startDateError || endDateError) {
            setDateRangeError(false)
            setEndDateError('');
            setStartDateError('');
        }
    }, [leaseStartDate, leaseEndDate]);

    useEffect(() => {
        if (leaseAmountError) {
            setLeaseAmountError('');
        }
    }, [currentLeaseAmountPerWeek]);

    const renderFields = () => (
        <ReportFormFields
            removeDefaultFields={true}
            style={{
                padding: '15px 0 0',
            }}
        >
            <ReportMUIPriceField
                name="currentLeaseAmount"
                id="current-lease-amount"
                placeholder="0"
                maxLength="10"
                label="Current Lease Amount Per Week"
                autoFocus
                customClass="property-investment-field"
                setValue={setCurrentLeaseAmountPerWeek}
                handleKeyPress={handleKeyPress}
                valueError={leaseAmountError}
                value={currentLeaseAmountPerWeek}
            />
            <ReportDateField
                id="lease-start-date"
                label="Lease Start Date"
                customClass="property-investment-field date"
                setDate={setLeaseStartDate}
                value={leaseStartDate}
                isDateError={dateRangeError || !!startDateError}
                errorMessage={startDateError}
            />
            <ReportDateField
                id="lease-end-date"
                label="Lease End Date"
                customClass="property-investment-field date"
                setDate={setLeaseEndDate}
                value={leaseEndDate}
                isDateError={dateRangeError || !!endDateError}
                errorMessage={endDateError}
                allowFutureDate
            />
            {
                isVIC &&
                <FormControlLabel
                    checked={acknowledge}
                    onChange={() => setAcknowledge(!acknowledge)}
                    control={<Checkbox
                        id="acknowledge-and-agree"
                        color="secondary"
                    />}
                    label={ACKNOWLEDGE_AND_AGREE}
                    sx={{
                        display: 'inline-block',
                        margin: '0 0 32px 0',
                        '& .MuiCheckbox-root': {
                            padding: 0,
                            marginRight: '9px',
                        },
                        '& .MuiTypography-root': {
                            color: Colors.BLACK_60_OPAQUE,
                            fontSize: '13px',
                            textAlign: 'justify',
                        }
                    }}
                />
            }
        </ReportFormFields>
    );

    const filename = `Property_Investment_Report_${props.propertyAddress && props.propertyAddress.replace(/\s/g, '_')}`;

    return (
        <div data-testid="property-investment-modal">
            <ReportForm
                propertyId={props.propertyId}
                icon="PIR-White.svg"
                title="Property Investment Report"
                fields={renderFields()}
                toggleReportModal={handleResetForm}
                triggerHandler={generate}
                reportType={Reports.PROPERTY_INVESTMENT_REPORT}
                reportName={props.propertyAddress}
                filename={filename}
                manualOpenModal
                entryPoint={props.entryPoint}
                disabled={
                    !!startDateError
                    || !!endDateError
                    || !!dateRangeError
                    || !!leaseAmountError
                    || (isVIC ? !acknowledge : false)
                }
            />
        </div>
    );
}

PropertyInvestmentReport.propTypes = {
    propertyAddress: PropTypes.string,
    propertyState: PropTypes.string,
    pageContext: PropTypes.string,
    toggleReportModal: PropTypes.func,
    entryPoint: PropTypes.string,
    propertyId: PropTypes.number,
};

PropertyInvestmentReport.defaultProps = {
    entryPoint: null,
};

export default PropertyInvestmentReport;
