import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SuggestionTypes from 'js/constants/suggestionTypes';
import {
    getAddressCollective,
    CLEAR_ADDRESS_COLLECTIVE,
} from 'js/actions/clapi';
import { routeCodes } from 'js/constants/routes';

function useAddressSuggestionType(props) {
    const dispatch = useDispatch();
    const address = useSelector(state => state.rapid.get('address'));
    const history = useHistory();

    const goTo = (pathname, state) => {
        dispatch({
            type: CLEAR_ADDRESS_COLLECTIVE,
        });

        const goToPdp = pathname.includes('property');
        props.trackSearchEvent && props.trackSearchEvent(SuggestionTypes.ADDRESS, goToPdp);
        history.push({
            pathname,
            search: props.historic && `?includeHistoric=${props.historic}`,
            state: {
                ...state,
                entryPoint: props.entryPoint,
                isActiveProperty: props.optionSelected?.isActiveProperty,
            },
        });
    };

    const goToPDP = (propertyId) => goTo(routeCodes.PROPERTY.path(props.optionSelected?.suggestion, propertyId), {
        suggestionType: props.optionSelected?.suggestionType,
    });

    const goToAddressSearch = () => goTo(routeCodes.SEARCH_RESULT.path(props.optionSelected?.suggestionType, props.optionSelected?.suggestion), {
        suggestion: [props.optionSelected],
        addressCollective: true,
    });

    useEffect(() => {
        if (props.optionSelected?.suggestionType === SuggestionTypes.ADDRESS) {
            dispatch(getAddressCollective(props.optionSelected, props.historic));
        }
    }, [props.optionSelected]);

    useEffect(() => {
        if (address?.metadata?.totalElements === 1 && address?.data?.length === 1) {
            const propertyId = address?.data[0].id;
            goToPDP(propertyId);
        } else if (address?.metadata) {
            goToAddressSearch();
        }
    }, [address]);
}

useAddressSuggestionType.propTypes = {
    entryPoint: PropTypes.string.isRequired,
    optionSelected: PropTypes.shape({
        suggestionType: PropTypes.string,
        suggestion: PropTypes.string,
        isActiveProperty: PropTypes.bool,
    }),
    historic: PropTypes.bool,
    trackSearchEvent: PropTypes.func,
}

export default useAddressSuggestionType;
