// Operators
import {
    MATERIALS_IN_EXTERNAL_WALL_FILTER,
    MATERIALS_IN_ROOF_FILTER
} from '../../helpers/Localization';

export const EQUALS_OPERATOR = 'equals';
export const AND_OPERATOR = 'and';
export const AND_NOT_OPERATOR = 'and not';
export const OR_OPERATOR = 'or';
export const CONTAINS_OPERATOR = 'contains';
export const STARTS_WITH_OPERATOR = 'starts with';
export const IS_OPERATOR = 'is';
export const COLLECTIVE_OPERATOR = 'collective';
export const GEODISTANCE_OPERATOR = 'geoDistance';
export const GEOPOLYGON_OPERATOR = 'geoPolygon'
export const NESTED_OPERATOR = 'nested';
export const COMPARE_OPERATOR = 'compare';
export const ADDRESS_PARAM = 'address';
export const DISTANCE = 'distance';

// Range Operator
export const GREATER_THAN_OPERATOR = '>=';
export const LESS_THAN_OPERATOR = '<=';
export const DOUBLE_EQUALS_OPERATOR = '==';

// Date Range Operator
export const DATE_FROM_OPERATOR = 'from';
export const DATE_TO_OPERATOR = 'to';
export const DATE_ON_OPERATOR = 'on';

// Fields for results format
export const OFFSET_PARAM = 'offset';
export const LIMIT_PARAM = 'limit';
export const SORT_PARAM = 'sort';
export const METADATA_PARAM = 'metadata';
export const DISTINCT_FIELDS_PARAM = 'distinctFields';

// Results Format
export const RESULTS_FORMAT = 'resultsFormat';
export const RESULTS_FORMAT_SORT = 'resultsFormatSort';

// Fields for filters
export const ADDRESS_COLLECTIVE_PARAM = 'addressCollective';
export const ADDRESS_CONTAINS_PARAM = 'addressContains';
export const EXACT_ADDRESS_PARAM = 'exactAddress';
export const IS_ACTIVE_PARAM = 'isActive';
export const ADDRESS_COMPLETE_PARAM = 'addressComplete';
export const BEDS_PARAM = 'beds';
export const CAR_SPACES_PARAM = 'carSpaces';
export const BATHS_PARAM = 'baths';
export const LAND_AREA_PARAM = 'landArea';
export const YEAR_BUILT_PARAM = 'yearBuilt';
export const TYPE_PARAM = 'type';
export const SUB_TYPE_PARAM = 'subType';
export const DEVELOPMENT_ZONE_DESCRIPTION_PARAM = 'developmentZoneDescription';
export const LAND_USE_PARAM = 'landUse';
export const SALES_LAST_SOLD_PRICE_PARAM = 'salesLastSoldPrice';
export const SALES_LAST_SALE_CONTRACT_DATE_PARAM = 'salesLastSaleContractDate';
export const SALES_LAST_SALE_SOURCE_PARAM = 'salesLastSaleSource';
export const SALES_LAST_SALE_IS_ARMS_LENGTH_PARAM = 'salesLastSaleIsArmsLength';
export const SALES_LAST_CAMPAIGN_LAST_LISTED_PRICE_PARAM = 'salesLastCampaignLastListedPrice';
export const SALES_LAST_SALE_SETTLEMENT_DATE_PARAM = 'salesLastSaleSettlementDate';
export const SALES_LAST_CAMPAIGN_END_DATE_PARAM = 'salesLastCampaignEndDate';
export const SALES_LAST_CAMPAIGN_START_DATE_PARAM = 'salesLastCampaignStartDate';
export const IS_ACTIVE_FOR_SALE_CAMPAIGN_PARAM = 'isActiveForSaleCampaign';
export const RENTAL_LAST_CAMPAIGN_LAST_LISTED_PRICE_PARAM = 'rentalLastCampaignLastListedPrice';
export const RENTAL_LAST_CAMPAIGN_END_DATE_PARAM = 'rentalLastCampaignEndDate';
export const RENTAL_LAST_CAMPAIGN_START_DATE_PARAM = 'rentalLastCampaignStartDate';
export const IS_ACTIVE_FOR_RENT_CAMPAIGN_PARAM = 'isActiveForRentCampaign';
export const LISTING_DATA_PARAM = 'listingData';
export const ADDRESS_PARCEL_SUBURB_STATE_PARAM = 'addressParcelSuburbState';
export const LEGAL_DESCRIPTION_PARAM = 'legalDescription';
export const TITLE_REFERENCE_PARAM = 'titleReference';
export const VOLUME_PARAM = 'volumeNumber';
export const FOLIO_PARAM = 'folioNumber';
export const VOLUME_FOLIO_DATA_PARAM = 'volumeFolioData';
export const ADDRESS_BUILDING_NAME_PARAM = 'addressBuildingName';
export const VALUATION_REFERENCE_PARAM = 'valuationReference';
export const CAPITAL_VALUE_PARAM = 'valuationCapitalValue';
export const VALUATION_DATE_PARAM = 'valuationAssessmentDate';
export const SALES_LAST_CAMPAIGN_LISTED_TYPE_PARAM = 'salesLastCampaignListedType';
export const SALES_CLASSIFICATION_PARAM = 'salesClassification';
export const HOLD_PERIOD_HOUSE = 'holdPeriodHouse';
export const HOLD_PERIOD_UNIT = 'holdPeriodUnit';
export const TENURE_DATA_PARAM = 'tenureData';
export const SALE_TYPE_PARAM = 'salesLastSaleTypeDescription';
export const OWNER_TYPE_PARAM = 'occupancyType';
export const SALES_LAST_CAMPAIGN_FIRST_LISTED_PRICE_DESCRIPTION = 'salesLastCampaignFirstListedPriceDescription';
export const RENTAL_LAST_CAMPAIGN_FIRST_LISTED_PRICE = 'rentalLastCampaignFirstListedPrice';
export const RENTAL_LAST_CAMPAIGN_AGENT = 'rentalLastCampaignAgent';
export const RENTAL_LAST_CAMPAIGN_AGENCY = 'rentalLastCampaignAgency';

export const ALL_WEATHER_ACCESS_TO_LAND_PARAM = 'allWeatherAccessToLand';
export const ALL_WEATHER_ROADS_TO_FARM_PARAM = 'allWeatherRoadsToFarm';
export const HOUSES_ON_FARM_PARAM = 'housesOnFarm';
export const IRRIGATED_AREA_PARAM = 'irrigatedArea';
export const DAMS_BORES_PARAM = 'damsBores';
export const PADDOCKS_YARDS_PARAM = 'paddocksYards';
export const PERMANENT_WATER = 'permanentWater';
export const SEALED_ROADS_TO_PROPERTY = 'sealedRoadsToProperty';

export const BOAT_RAMP_MOORING_SPACE = 'boatRampMooringSpace';
export const FENCED = 'fenced';
export const GRANNY_FLAT_SELF_CONTAINED = 'grannyFlatSelfContained';
export const LOCKUP_GARAGES = 'lockupGarages';
export const MATERIALS_IN_EXTERNAL_WALLS = MATERIALS_IN_EXTERNAL_WALL_FILTER;
export const MATERIALS_IN_ROOF = MATERIALS_IN_ROOF_FILTER;
export const SWIMMING_POOL = 'swimmingPool';
export const NUMBER_OF_TENNIS_COURTS = 'noOfTennisCourts';
export const TREES_ON_LAND = 'treesOnLand';
export const ACCESS_AVAILABLE_24_HOURS_PARAM = 'accessAvailable24Hrs';
export const ACCESS_SECURITY_INSTALLED_PARAM = 'accessSecurityInstalled';
export const CLOSE_TO_PUBLIC_TRANSPORT_PARAM = 'closeToPublicTransport';
export const LIFT_INSTALLED_PARAM = 'liftInstalled';
export const ON_MAIN_ROAD_PARAM = 'onMainRoad';
export const SEWAGE_PARAM = 'sewage';
export const TOWN_SEWERAGE_AVAILABLE_PARAM = 'townSewerageAvailable';
export const TOWN_WATER_AVAILABLE_PARAM = 'townWaterAvailable';
export const WATER_PARAM = 'water';
export const DISTANCE_HOSPITAL = 'distanceToHospital';
export const DISTANCE_RIVER_FRONTAGE = 'distanceToRiverFrontage';
export const DISTANCE_SCHOOL = 'distanceToSchool';
export const DISTANCE_SEA_RIVER_CANAL = 'distanceToSeaRiverCanal';
export const DISTANCE_SHOPS = 'distanceToShops';
export const FEE_CODE_PARAM = 'feeCode';
export const FACTORY_AREA_PARAM = 'factoryArea';
export const MEZZANINE_AREA_PARAM = 'mezzanineArea';
export const OFFICE_SHOWROOM_AREA_PARAM = 'officeShowroomArea';
export const OFFICE_AREA_PARAM = 'officeArea';
export const OTHER_AREA_PARAM = 'otherArea';
export const SHOP_AREA_PARAM = 'shopArea';
export const SHOW_ROOM_AREA_PARAM = 'showroomArea';
export const WAREHOUSE_AREA_PARAM = 'warehouseArea';
export const WORKSHOP_AREA_PARAM = 'workshopArea';

export const CANAL_FRONTAGE = 'canalFrontage';
export const COAST_FRONTAGE = 'coastFrontage';
export const GOLF_FRONTAGE = 'golfFrontage';
export const HIGH_EXPOSURE = 'highExposure';
export const LAKE_FRONTAGE = 'lakeFrontage';
export const RIVER_FRONTAGE = 'riverFrontage';
export const SCENIC_VIEW = 'scenicView';
export const WATER_FRONTAGE = 'waterFrontage';

export const CURRENT_UNIMPROVED_LAND_VALUE_PARAM = 'currentUnimprovedLandValue';
export const UNIMPROVED_CAPITAL_VALUE_PARAM = 'unimprovedCapitalValue';
export const UNIMPROVED_CAPITAL_VALUE_DATE_PARAM = 'unimprovedCapitalValueDate';

export const VALUATION_LAND_VALUE = 'valuationLandValue';
export const VALUATION_IMPROVEMENTS_VALUE = 'valuationImprovementsValue';

export const AIR_CONDITIONED = 'airConditioned';
export const BUILT_IN_WARDROBES = 'builtInWardrobes';
export const DUCTED_HEATING = 'ductedHeating';
export const DUCTED_VACUUM = 'ductedVacuum';
export const FIREPLACE = 'fireplace';
export const OPEN_PLAN = 'openPlan';
export const POLISHED_FLOORS = 'polishedFloors';
export const RENOVATED = 'renovated';
export const DINING_ROOMS = 'diningRooms';
export const FAMILY_RUMPUS_ROOMS = 'familyRumpusRooms';
export const FLOOR_LEVELS_INSIDE = 'floorLevelsInside';
export const LOUNGE_ROOMS = 'loungeRooms';
export const LOUNGE_DINING_ROOMS_COMBINED = 'loungeDiningRoomsCombined';
export const KITCHENS = 'kitchens';
export const NUMBER_OF_STUDY_ROOMS = 'noOfStudyRooms';
export const TOILETS = 'toilets';
export const HERITAGE_OTHER_CLASSIFICATION = 'heritageOtherClassification';

export const BUILDING_AREA = 'buildingArea';
export const EQUIVALENT_BUILDING_AREA = 'equivalentBuildingArea';
export const FLOOR_LEVEL_OF_UNIT = 'floorLevelOfUnit';
export const FREE_STANDING_BUILDING = 'freeStandingBuilding';
export const FLOOR_AREA = 'floorArea';
export const FLOOR_LEVEL_IS_INSIDE_UNIT = 'floorLevelsInsideUnit';
export const FLATS = 'flats';
export const STREET_FRONTAGE = 'streetFrontage';
export const TOTAL_FLOORS_IN_BUILDING = 'totalFloorsInBuilding';
export const YEAR_BUILDING_REFURBISHED = 'yearBuildingRefurbished';
export const YEAR_ESTABLISHED = 'yearEstablished';
export const IS_FOR_SALE = 'isForSale';
export const SALES_LAST_CAMPAIGN_DAYS_ON_MARKET = 'salesLastCampaignDaysOnMarket';
export const RENTAL_LAST_CAMPAIGN_DAYS_ON_MARKET = 'rentalLastCampaignDaysOnMarket';
export const SALES_LAST_CAMPAIGN_AGENT = 'salesLastCampaignAgent';
export const SALES_LAST_CAMPAIGN_AGENCY = 'salesLastCampaignAgency';

export const RENTAL_LAST_CAMPAIGN_LAST_LISTED_PERIOD = 'rentalLastCampaignLastListedPeriod';

// Fields for radius search
export const RADIUS_PARAM = 'radius';
export const LOCATION_PARAM = 'location';
export const RADIUS_SEARCH_FORMAT = 'radiusSearch';
export const SUBURB_ONLY = 'suburbOnly';
export const EXCLUDE_PROPERTY = 'excludeProperty';
export const TERRITORIES_SEARCH_FORMAT = 'geoPolygon';

export const DEFAULT_RESULTS_FORMAT = {
    [OFFSET_PARAM]: 0,
    [LIMIT_PARAM]: 20,
    [METADATA_PARAM]: false,
};

export const MAX_PROPERTIES_PER_PAGE = 10;
export const MAX_PROPERTIES_PER_BATCH = 3000;
export const MAX_PAGE_PER_BATCH = 1000;
export const MAX_BATCH_PROPERTIES = 10000;
