import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';

import { getRoute, routeCodes } from '../../../../constants/routes';
import Option from '../../../../constants/options/dropdownOptions';
import { getAssetDomain } from '../../../../constants/assets';
import TOOLTIP from '../../../../constants/tooltipText';
import SearchValidation from '../SearchValidation';
import Search from '../../../../constants/rapidSearch';
import CurrentOwnerCheckbox from '../nameSearch/CurrentOwnerCheckbox';
import { isAU, isNZ } from '../../../../constants/crux';
import { trackCompanySearch } from '../../../../actions/segment';
import SEGMENT from '../../../../constants/segment';
import MoreActions from '../MoreActions';
import Commons from '../../../../helpers/Commons';
import LocalStorageHelper, { SEARCH_BAR_TOOLTIP_KEY } from '../../../../helpers/LocalStorageHelper';
import listPropertyPreview from '../../../../constants/listPropertyPreview';
import FieldTooltip from '../FieldTooltip';
import { conjunctUrlParams } from '../../../../helpers/search/ParamsBuilder';
import populateNameSearchFields from '../../../../helpers/nameSearch/populateOwnerSearchFields';
import ApiHelper from '../../../../helpers/ApiHelper';
import { saveSearchType } from '../../../../actions/componentAccess';
import { cruxLoader } from '../../../../actions/crux';
import StyledSearchButton from '../../../common/styledMUI/StyledSearchButton';
import SearchField from '../../../common/textFields/SearchField';
import ExactSearchCheckbox from '../buildingSearch/ExactSearchCheckbox';
import saveAndGetUserSetting from '../../../../helpers/userPreferences/saveAndGetUserSetting';
import { SELECTED_STATE_COMPANY } from '../../../../helpers/Localization';
import StateSelect from '../StateSelect';

class CompanySearch extends Component {
    static propTypes = {
        route: PropTypes.object.isRequired,
        dispatch: PropTypes.func,
        showPopper: PropTypes.bool,
        deed7: PropTypes.bool,
        isCheckboxVisible: PropTypes.bool,
        config: PropTypes.object.isRequired,
        enableVIC: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            companyName: '',
            addressState: '',
            exactSearch: false,
            isCurrentOwner: true,
            isCompanyNameFieldError: false,
            companyNameFieldErrorMessage: '',
            isStateFieldError: false,
            stateFieldErrorMessage: '',
            addressSuburb: '',
            anchorEl: [],
            open: [],
            isViewed: [],
            queryString: '',
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
    }

    componentDidUpdate(prevProps) {
        const { route } = this.props;
        if (route.id !== routeCodes.COMPANY_SEARCH_RESULT.id &&
            prevProps.route !== route) {
            this.clearAllFields();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        this.clearAllFields();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {
            isStateFieldError: prevState.isStateFieldError,
            isCompanyNameFieldError: prevState.isCompanyNameFieldError,
        };
        const { location: { search } = {} } = nextProps;

        // Field Validations
        // Revert field to normal state upon receiving valid input
        if (prevState.isCompanyNameFieldError && prevState.companyName !== '') {
            nextState.isCompanyNameFieldError = false;
        }
        if (prevState.isStateFieldError && prevState.addressState !== '') {
            nextState.isStateFieldError = false;
        }

        const routePath = getRoute(nextProps.match.path);
        let formattedState = {};
        if (search && search !== prevState.queryString &&
            routePath.id === routeCodes.COMPANY_SEARCH_RESULT.id) {
            nextState.queryString = search;
            formattedState = populateNameSearchFields(search);
        }

        return { ...nextState, ...formattedState };
    }

    clearAllFields = () => {
        this.setState({
            companyName: '',
            addressState: '',
            addressSuburb: '',
        });
    }

    validateCompanyName(companyName) {
        // Validate whether companyName field is empty or not
        if (companyName.trim() === '') {
            this.setState({
                isCompanyNameFieldError: true,
                companyNameFieldErrorMessage: Search.ERROR_MESSAGE.companyNameRequired,
            });
        }
    }

    validateAddressState(addressState) {
        // Validate whether addressState field is empty or not
        if (addressState === '') {
            this.setState({
                isStateFieldError: true,
                stateFieldErrorMessage: Search.ERROR_MESSAGE.stateRequired,
            });
        }
    }

    redirectPage = (uri) => {
        const { history, dispatch } = this.props;
        history.push(uri);
        const goToPdp = uri.includes('property');
        this.trackMixpanelEvent(goToPdp);
        if (goToPdp) {
            this.clearAllFields();
            dispatch(saveSearchType(Option.COMPANY));
        }
    };

    companySearchSubmit = () => {
        const {
            isCurrentOwner, companyName, addressState, addressSuburb, exactSearch,
        } = this.state;

        const { dispatch } = this.props;

        const ownerName = { firstName: companyName, lastName: '' };

        const listingParams = {
            isCurrentOwner: isCurrentOwner ? 'true' : 'true, false',
            exactSearch,
            state: addressState,
            addressSuburb,
            ownerName,
            dispatch,
            pathName: routeCodes.COMPANY_SEARCH_RESULT.path,
            searchParams: this.constructQueryParams(),
        };

        this.validateCompanyName(companyName);
        this.validateAddressState(addressState);

        // companyName and addressState should not be empty before proceeding
        if (companyName.trim() !== '' && (isNZ || addressState !== '')) {
            dispatch(cruxLoader());
            if (addressState) {
                saveAndGetUserSetting(dispatch, {
                    name: SELECTED_STATE_COMPANY,
                    value: addressState,
                });
            }
            ApiHelper.getRedirectUrl(listingParams, this.redirectPage);
        }
    }

    trackMixpanelEvent = (goToPdp) => {
        const { dispatch, route: { pageContext } = {}, activeView } = this.props;

        const {
            companyName,
            addressState,
            addressSuburb,
            isCurrentOwner,
            exactSearch,
        } = this.state;

        const _addressState = addressState ? `, ${addressState}` : '';
        const _addressSuburb = addressSuburb ? `, ${addressSuburb}` : '';
        dispatch(trackCompanySearch({
            pageContext,
            searchString: `${companyName}${_addressState}${_addressSuburb}`,
            entryPoint: SEGMENT.EVENT_PROPERTIES.TYPED,
            currentOwner: isCurrentOwner,
            searchType: SEGMENT.EVENT_PROPERTIES.COMPANY,
            exactSearch,
            activeView: !goToPdp && activeView,
        }));
    };

    constructQueryParams = () => {
        const {
            isCurrentOwner, companyName,
            addressState, addressSuburb, exactSearch,
        } = this.state;

        const formattedParams = {
            exactSearch,
            isCurrentOwner,
            companyName,
            addressState,
            addressSuburb,
        };
        return conjunctUrlParams(formattedParams);
    };

    toggleState = (stateName) => {
        this.setState(prevState => ({
            ...prevState,
            [stateName]: !prevState[stateName],
        }));
    };

    handleFocusPopper = (event, index) => {
        if (Commons.get(LocalStorageHelper.get(SEARCH_BAR_TOOLTIP_KEY), `is-${listPropertyPreview.SEARCH_TYPE.COMPANY}-${index}-viewed`)) return;
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: {
                ...state.anchorEl,
                [index]: currentTarget,
            },
            open: {
                ...state.open,
                [index]: true,
            },
        }));
    };

    handleClosePopper = (event, index) => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: {
                ...state.anchorEl,
                [index]: currentTarget,
            },
            open: {
                ...state.open,
                [index]: false,
            },
            isViewed: {
                ...state.isViewed,
                [index]: true,
            },
        }));
    };

    handleClickAway = (event, index) => {
        setTimeout(() => {
            this.setState(state => ({
                open: {
                    ...state.open,
                    [index]: false,
                },
            }));
        }, 150);
    };

    handleScroll = () => {
        this.setState(prevState => ({ open: !prevState.open }));
    };

    handleStateChange = (type) => {
        this.setState({ addressState: type });
    }

    render() {
        const { isCheckboxVisible, location, deed7, enableVIC } = this.props;

        const {
            isStateFieldError, isCompanyNameFieldError, companyNameFieldErrorMessage,
            stateFieldErrorMessage, addressState, isCurrentOwner, anchorEl, open, isViewed, exactSearch,
        } = this.state;
        const {
            STATE, STATE_VIC_ENABLED, SUBURB, COMPANY_NAME,
        } = TOOLTIP.TEXT.COMPANY_SEARCH;
        const { STATE_OPTIONS_COMPANY_SEARCH_WITH_DEED7, STATE_OPTIONS_COMPANY_SEARCH } = Option;
        const isVicAvailable = enableVIC || deed7;
        const stateToolTip = isVicAvailable ? STATE_VIC_ENABLED : STATE;
        const stateOptions = isVicAvailable ? STATE_OPTIONS_COMPANY_SEARCH_WITH_DEED7 : STATE_OPTIONS_COMPANY_SEARCH;

        const inputProps = [
            {
                field: 'companyName',
                placeholder: 'Enter a company name *',
                onChange: event => this.setState({ companyName: event.target.value }),
                tooltipTxt: COMPANY_NAME,
                validation: {
                    message: companyNameFieldErrorMessage,
                    onError: isCompanyNameFieldError,
                },
                containerSize: 6,
                placement: 'bottom-start',
            },
            {
                field: 'addressState',
                containerSize: 3,
            },
            {
                field: 'addressSuburb',
                placeholder: 'Enter a Suburb',
                onChange: event => this.setState({ addressSuburb: event.target.value }),
                tooltipTxt: SUBURB,
                containerSize: isAU ? 3 : 6,
                placement: isAU ? 'bottom' : 'bottom-start',
            },
        ];
        return (
            <div className={(!isCheckboxVisible ? 'search-inputs more-actions' : 'search-inputs')}>
                <div className="company-search-primary-fields">
                    <Grid container className="grid-container">
                        {
                            inputProps.map(({
                                field, placeholder, onChange, tooltipTxt, validation, containerSize, placement,
                            }, index) => {
                                if (field !== 'addressState') {
                                    return (
                                        <Grid key={`grid-${index}-${field}`} item className="item" xs={containerSize}>
                                            <div
                                                className={`form-input-search-field ${field}-field ${(validation && validation.onError) && 'error'}`}
                                            >
                                                <SearchField
                                                    value={this.state[field]}
                                                    type="text"
                                                    className="form-control"
                                                    id={field}
                                                    placeholder={placeholder}
                                                    onChange={onChange}
                                                    onFocus={event => this.handleFocusPopper(event, index)}
                                                    autoComplete="off"
                                                    onBlur={event => this.handleClickAway(event, index)}
                                                    error={!!validation?.onError}
                                                    color="primary"
                                                />
                                                <FieldTooltip
                                                    id={index}
                                                    key={`company-search-popper-${index}-${field}`}
                                                    className={`${!isCheckboxVisible ? 'search-results' : ''} company-search-tooltip ${field}-tooltip ${isAU && 'au-tooltip'}`}
                                                    open={(open[index] && !isViewed[index])}
                                                    anchorEl={anchorEl[index] || null}
                                                    handleClose={event => this.handleClosePopper(event, index)}
                                                    tooltipTxt={tooltipTxt}
                                                    searchType={listPropertyPreview.SEARCH_TYPE.COMPANY}
                                                    placement={placement}
                                                />

                                                {
                                                    validation &&
                                                    <SearchValidation
                                                        message={validation.message}
                                                        onError={validation.onError}
                                                    />
                                                }

                                            </div>
                                        </Grid>
                                    );
                                }
                                return (
                                    isAU &&
                                    <StateSelect
                                        key={`${index}-${field}`}
                                        addressState={addressState}
                                        options={stateOptions}
                                        toolTipText={stateToolTip}
                                        showToolTip
                                        isStateFieldError={isStateFieldError}
                                        stateFieldErrorMessage={stateFieldErrorMessage}
                                        handleStateChange={this.handleStateChange}
                                        userSettingKey={SELECTED_STATE_COMPANY}
                                    />
                                );
                            })
                        }
                    </Grid>
                    {
                        !isCheckboxVisible &&
                        <MoreActions isChecked={isCurrentOwner}>
                            <CurrentOwnerCheckbox
                                checked={isCurrentOwner}
                                onChange={() => this.toggleState('isCurrentOwner')}
                            />
                            <p className="tooltipText">{TOOLTIP.TEXT.COMPANY_SEARCH.OWNER_NAME}</p>
                            <ExactSearchCheckbox
                                checked={exactSearch}
                                onChange={() => this.toggleState('exactSearch')}
                            />
                            <p className="tooltipText">{TOOLTIP.TEXT.BUILDING_SEARCH.EXACT_SEARCH}</p>
                        </MoreActions>
                    }
                    <StyledSearchButton
                        data-testid="company-search-button"
                        className="button-primary name-search-btn"
                        onClick={this.companySearchSubmit}
                        disabled={location.search === `?${this.constructQueryParams()}`}
                    >
                        <img
                            src={getAssetDomain('search_icon_white.png')}
                            className="search-icon"
                            alt="company search"
                        />
                    </StyledSearchButton>
                </div>
                { isCheckboxVisible &&
                <div id="advanced-search-options" className={isNZ ? 'smaller-options-container' : ''}>
                    <CurrentOwnerCheckbox
                        checked={isCurrentOwner}
                        onChange={() => this.toggleState('isCurrentOwner')}
                    />
                    <ExactSearchCheckbox
                        checked={exactSearch}
                        onChange={() => this.toggleState('exactSearch')}
                    />
                </div>
                }
            </div>
        );
    }
}

export default connect(state => ({
    activeView: state.searchResults.get('activeView'),
}))(withRouter(CompanySearch));

