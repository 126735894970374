import { cloneElement, useEffect, useRef, useState } from 'react';
import { styled, darken } from '@mui/material';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import BlockUi from 'react-block-ui';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import SavedList from './savedList/SavedList';
import CreateSavedList from './CreateSavedList';
import {
    getSavedList,
    getWatchList,
    addSavedListInfo,
    addWatchListInfo
} from 'js/actions/watchList';
import CruxSnackBar from 'js/components/common/CruxSnackBar';
import UserPrefAPI from 'js/api/userPreferences';
import Colors from 'js/constants/colors'
import MUITooltip from 'js/components/common/MUITooltip';
import { UNSUBSCRIBED, UNAVAILABLE, NOT_IN_HISTORICAL } from 'js/constants/tooltipText';
import { selectSelectedAccount } from '../../../selectors/linkedAccount';

const StyledWatchButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'loading',
})(({ loading }) => ({
    minWidth: 32,
    padding: 0,
    backgroundColor: loading ? Colors.BLACK_4_OPAQUE : 'white',
    '&:hover': {
        backgroundColor: darken('#fff', 0.1),
    },
}));

const StyledWatchButtonForListView = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'loading',
})(({ loading, isActive }) => ({
    width: '100%',
    justifyContent: 'flex-start',
    padding: '6px 16px 6px 24px',
    backgroundColor: loading || isActive ? Colors.BLACK_4_OPAQUE : 'transparent',
    boxShadow: 'none',
    fontWeight: 400,
    color: Colors.SECONDARY.MAIN,
    fontSize: 'inherit',
    transition: 'inherit',
    '&:hover': {
        backgroundColor: 'inherit',
        boxShadow: 'none',
    },
    '&.Mui-disabled': {
        color: Colors.SECONDARY.MAIN,
        backgroundColor: 'transparent',
        opacity: 'inherit',
    },
}));

const WatchListDropdown = (props) => {
    const {
        properties,
        page,
        entryPoint,
        searchTab,
        isPropertyExist,
        isHistoricProperty,
        entitled,
        placement = 'bottom-end',
        children,
        shouldCallListInfo,
        forListView,
        popperStyle,
        paperStyle,
    } = props;
    const dispatch = useDispatch();
    const { selectedClAppAccountUserGuid } = useSelector(selectSelectedAccount, shallowEqual);
    const watchList = useSelector(({ watchList }) => watchList.get('watchList'), shallowEqual);
    const savedList = useSelector(({ watchList }) => watchList.get('savedList'), shallowEqual);
    const info = useSelector(({ watchList }) => watchList.get('info'), shallowEqual);

    const listRef = useRef(null);

    const [openSavedListsPopper, setOpenSavedListsPopper] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [allSavedList, setAllSavedList] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarError, setSnackBarError] = useState('');
    const [severity, setSeverity] = useState('');
    const [starred, setStarred] = useState(false);
    const [starLoading, setStarLoading] = useState(false);
    const [successSavedListActivity, setSuccessSavedListActivity] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [searchActive, setSearchActive] = useState(false);

    const fetchSavedListActivity = () => {
        setStarLoading(true);
        UserPrefAPI
            .getSavedPropertyInfo(selectedClAppAccountUserGuid, properties)
            .then((res) => {
                if (res?.existsInSavedLists) {
                    dispatch(addSavedListInfo({
                        id: res?.savedLists?.map(list => list.id),
                        properties,
                    }));
                }
                if (res?.existsInWatchList) {
                    dispatch(addWatchListInfo(properties));
                }
                setSuccessSavedListActivity(true);
            })
            .catch(() => {
                setSuccessSavedListActivity(false);
            })
            .finally(() => {
                setStarLoading(false);
            });
    };

    useEffect(() => {
        if ((isHistoricProperty && !starred) || !isPropertyExist || !entitled || !properties?.length) {
            setDisabled(true);
        } else if (disabled) {
            setDisabled(false);
        }
    }, [
        isHistoricProperty,
        starred,
        isPropertyExist,
        entitled,
        properties,
    ]);

    useEffect(() => {
        const { watchList = [], savedList = [] } = info || {};
        setStarred(properties.every(property => watchList.includes(property) ||
            savedList.map(s => s.properties)
                .some(savedListProperties => savedListProperties.includes(property))));
    }, [JSON.stringify(info), properties]);

    // Initially highlight or un-highlight the star icon
    // fetch saved list info immediately for default trigger button
    useEffect(() => {
        if ((properties?.length > 1 || (shouldCallListInfo && properties?.length && properties[0]))
            && entitled) {
            fetchSavedListActivity();
        }
    }, [JSON.stringify(properties), entitled]);

    useEffect(() => {
        if (openSavedListsPopper) {
            if (!savedList.fetching && !savedList.success) {
                dispatch(getSavedList());
            }
            if (!watchList.fetching && !watchList.success) {
                dispatch(getWatchList());
            }
            if (!successSavedListActivity && !starLoading) {
                fetchSavedListActivity();
            }
        }
    }, [openSavedListsPopper]);

    useEffect(() => {
        setAllSavedList([
            {
                description: 'My Watch List',
                propertiesCount: watchList.propertiesCount,
                id: 0,
            },
            ...savedList.list,
        ]);
    }, [watchList, savedList]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget || event.target);
        setOpenSavedListsPopper(!openSavedListsPopper);
        setSearchActive(false);
    };

    const handleStar = () => {
        const iconOpacity = starLoading ? '0.4' : '1';
        let icon = (
            <StarBorderIcon
                sx={{
                    opacity: iconOpacity,
                    color: disabled || !successSavedListActivity
                        ? Colors.SECONDARY.MAIN
                        : '#757575',
                }}
            />
        );
        if (starred) {
            icon = <StarIcon sx={{ opacity: iconOpacity, fill: '#FFB400' }} />;
        }

        const style = {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
        };
        if (forListView) Object.assign(style, { right: '10px' });
        return (
            <span
                style={style}
            >
                {
                    starLoading &&
                    <CircularProgress
                        size={18}
                        sx={{
                            left: 0,
                            right: 0,
                            margin: 'auto',
                            color: Colors.PRIMARY.MAIN,
                            position: 'absolute',
                        }}
                    />
                }
                { icon }
            </span>
        );
    };

    const constructStarButton = () => {
        return forListView ? <StyledWatchButtonForListView
                disableRipple
                variant="contained"
                color="primary"
                onClick={handleClick}
                aria-describedby={id}
                disabled={disabled}
                loading={starLoading}
                isActive={openSavedListsPopper}
            >
                {/*https://github.com/mui/material-ui/issues/30036*/}
                &#8288;
                { handleStar() }
                Add to Saved List
            </StyledWatchButtonForListView>
            : <StyledWatchButton
                variant="contained"
                color="primary"
                onClick={handleClick}
                aria-describedby={id}
                disabled={disabled}
                loading={starLoading}
            >
                { handleStar() }
            </StyledWatchButton>
    }

    const handleErrorMessage = () => {
        if (!successSavedListActivity) {
            return 'Sorry, there was an error fetching your lists, please click to retry';
        } else if (!isPropertyExist) {
            return UNAVAILABLE;
        } else if (isHistoricProperty && !starred) {
            return NOT_IN_HISTORICAL;
        } else if (!entitled) {
            return UNSUBSCRIBED;
        }
        return '';
    };

    const handleSnackBar = (severity, errorMessage) => {
        setSnackBarError(errorMessage);
        setSeverity(severity);
        setOpenSnackBar(true);
    }

    const canBeOpen = openSavedListsPopper && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;
    const stopPropagation = e => e.stopPropagation();

    return (
        <>
            <ClickAwayListener onClickAway={() => setOpenSavedListsPopper(false)}>
                <div className="saved-list-star-wrapper">
                    <div style={{ marginLeft: 8 }}>
                        <MUITooltip
                            title={handleErrorMessage()}
                            arrow
                            placement="bottom"
                            longTooltip
                        >
                            <div>
                                {
                                    children ?
                                        cloneElement(children, {
                                            onClick: handleClick,
                                            entitled,
                                            disabled,
                                        })
                                        : constructStarButton()
                                }
                            </div>
                        </MUITooltip>
                    </div>
                    <Popper
                        id={id}
                        open={openSavedListsPopper}
                        anchorEl={anchorEl}
                        onKeyDown={stopPropagation}
                        onMouseDown={stopPropagation}
                        transition
                        disablePortal
                        placement={placement}
                        sx={{
                            zIndex: 3,
                            ...popperStyle,
                        }}
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <div>
                                    <BlockUi
                                        tag="div"
                                        blocking={savedList.fetching || watchList.fetching || starLoading}
                                    >
                                        <Paper
                                            sx={{
                                                borderRadius: '4px',
                                                p: 1,
                                                mt: 1,
                                                width: 334,
                                                marginTop: 1,
                                                marginBottom: 1,
                                                ...paperStyle,
                                            }}
                                        >
                                            <Grid container p={1}>
                                                <Grid item xs={11}>
                                                    <Typography
                                                        variant="h7"
                                                    >
                                                        Add to Saved List
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="search saved list"
                                                        sx={{
                                                            padding: 0,
                                                        }}
                                                        onClick={
                                                            () => setSearchActive(prev => !prev)
                                                        }
                                                    >
                                                        <SearchIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                            <SavedList
                                                list={allSavedList}
                                                properties={properties}
                                                handleSnackBar={handleSnackBar}
                                                listRef={listRef}
                                                pageContext={page}
                                                entryPoint={entryPoint}
                                                searchTab={searchTab}
                                                fetchSavedListActivity={fetchSavedListActivity}
                                                searchActive={searchActive}
                                                isHistoricProperty={isHistoricProperty}
                                            />
                                            <CreateSavedList
                                                handleSnackBar={handleSnackBar}
                                                listRef={listRef}
                                                page={page}
                                                searchTab={searchTab}
                                                properties={properties}
                                                isHistoricProperty={isHistoricProperty}
                                            />
                                        </Paper>
                                    </BlockUi>
                                    <CruxSnackBar
                                        open={openSnackBar}
                                        severity={severity}
                                        message={snackBarError}
                                        onClose={() => setOpenSnackBar(false)}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            top: '100% !important',
                                            left: '0px !important',
                                            minWidth: 300,
                                            transform: 'initial !important',
                                            width: 334,
                                            maxWidth: 334,
                                            marginTop: '-4px',
                                            whiteSpace: 'pre-wrap',
                                            '& .MuiPaper-root': {
                                                width: '100%',
                                                minHeight: '48px',
                                                alignItems: 'center',
                                                '& .MuiAlert-message': {
                                                    wordBreak: 'break-word',
                                                },
                                                '& .MuiAlert-icon, .MuiAlert-message, .MuiAlert-action': {
                                                    paddingTop: '0',
                                                    paddingBottom: '0',
                                                }
                                            },
                                        }}
                                    />
                                </div>
                            </Fade>
                        )}
                    </Popper>
                </div>
            </ClickAwayListener>
        </>
    );
}

WatchListDropdown.propTypes = {
    properties: PropTypes.array,
    page: PropTypes.string,
    entryPoint: PropTypes.string,
    searchTab: PropTypes.string,
    entitled: PropTypes.bool,
    placement: PropTypes.string,
    shouldCallListInfo: PropTypes.bool,
    forListView: PropTypes.bool,
    popperStyle: PropTypes.object,
    paperStyle: PropTypes.object,
}

export default WatchListDropdown;
