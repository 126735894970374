import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Button, styled } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { connect } from 'react-redux';
import ReportCollectionSearchResult, { ConnectedReportCollection as ReportCollectionPdp } from './ReportCollection';
import { routeCodes } from '../../constants/routes';
import TOOLTIP from '../../constants/tooltipText';
import { HISTORIC } from '../../constants/propertyDetail';
import ButtonWithTooltip from '../common/ButtonWithTooltip';
import StyledMenuButton from '../common/styledMUI/StyledMenuButton';
import MUITooltip from '../common/MUITooltip';

const StyledButton = styled(Button)({
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: '#3399FF',
    border: '1px solid #3399FF',
    '&.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.26)',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        background: 'none',
    },
    '& .MuiSvgIcon-root': {
        fontSize: '18px',
    },
});

StyledButton.displayName = 'StyledButton';

export class ReportMenu extends Component {
    static propTypes = {
        dispatch: PropTypes.func,
        match: PropTypes.object,
        property: PropTypes.object,
        userSummaryApiSuccess: PropTypes.bool,
        reportRoles: PropTypes.array,
        tooltipId: PropTypes.string,
        toggleReportModal: PropTypes.func,
        isVictoria: PropTypes.bool,
        avmDetails: PropTypes.object,
        isEntitlementExists: PropTypes.bool,
        displayRentalEstimate: PropTypes.bool,
        route: PropTypes.object,
        index: PropTypes.number,
        entryPoint: PropTypes.string,
        isPropertyExist: PropTypes.bool,
        isHistoricProperty: PropTypes.bool,
        isFetchingAdditionalDetails: PropTypes.bool,
        showReport: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            isReportMenuOpen: false,
        };
    }

    setTooltipMessage = () => {
        const { userSummaryApiSuccess, isPropertyExist, route, isHistoricProperty, isEntitlementExists } = this.props;
        const propertyExists = (route === routeCodes.PROPERTY) ? isPropertyExist : true;
        if (isHistoricProperty) {
            return HISTORIC.FEATURE_NOT_AVAILABLE;
        }
        if (!isEntitlementExists) {
            return TOOLTIP.TEXT.MENU.UNAVAILABLE;
        }
        return (userSummaryApiSuccess && propertyExists) ? TOOLTIP.TEXT.MENU.UNSUBSCRIBED: TOOLTIP.TEXT.MENU.UNAVAILABLE;
    };

    handleOnClickReportMenu = () => {
        this.setState((_state) => ({
            isReportMenuOpen: !_state.isReportMenuOpen,
        }));
    };

    render() {
        const {
            tooltipId,
            userSummaryApiSuccess,
            reportRoles,
            match,
            property,
            route,
            isVictoria,
            entryPoint,
            isPropertyExist,
            isHistoricProperty,
            isFetchingAdditionalDetails,
            showReport,
            toggleReportModal,
        } = this.props;

        // Fixed for the card view always disabling the report menu
        const propertyExists = (route === routeCodes.PROPERTY) ? isPropertyExist : true;

        if (userSummaryApiSuccess &&
            !!reportRoles?.length &&
            propertyExists && !isHistoricProperty) {
            if (route === routeCodes.PROPERTY) {
                return (
                    <ReportCollectionPdp
                        {...this.props}
                        displaySOIReport={isVictoria}
                        showReport={this.state.isReportMenuOpen}
                        toggleReportModal={this.handleOnClickReportMenu}
                    >
                        <StyledMenuButton
                            variant="contained"
                            color="primary"
                            className="icon-right reports-dropdown-trigger"
                            onClick={this.handleOnClickReportMenu}
                        >
                            <span>Reports</span>
                            { this.state.isReportMenuOpen ? <ExpandLess /> : <ExpandMore /> }
                        </StyledMenuButton>
                    </ReportCollectionPdp>
                );
            }
            return (
                <ReportCollectionSearchResult
                    match={match}
                    property={property}
                    route={route}
                    entryPoint={entryPoint}
                    reportRoles={reportRoles}
                    showReport={showReport}
                    toggleReportModal={toggleReportModal}
                >
                    <StyledButton
                        variant="outlined"
                        onClick={toggleReportModal}
                        disabled={isFetchingAdditionalDetails}
                    >
                        <FilePresentIcon />
                        <span>Reports</span>
                        { showReport ? <ExpandLess /> : <ExpandMore /> }
                    </StyledButton>
                </ReportCollectionSearchResult>
            );
        } else {
            return (
                <Fragment>
                    {
                        (route === routeCodes.PROPERTY)
                            ? (
                                <div data-for={`${tooltipId}-${property.id}`}>
                                    <ButtonWithTooltip
                                        className="button-primary icon-right reports-dropdown-trigger disabled"
                                        tooltipMessage={this.setTooltipMessage()}
                                        tooltipPosition="bottom"
                                        tooltipId={`${tooltipId}-${property.id}`}
                                        isDisabled={isHistoricProperty}
                                        displayTooltip
                                    >
                                        <span>Reports</span>
                                        <ExpandMore />
                                    </ButtonWithTooltip>
                                </div>
                            )
                            : (<MUITooltip
                                    arrow
                                    title={this.setTooltipMessage()}
                                    placement="bottom"
                                >
                                    <span>
                                        <StyledButton
                                            variant="outlined"
                                            disabled
                                        >
                                            <FilePresentIcon
                                                sx={{
                                                    width: '18px',
                                                    height: '18px',
                                                }}
                                            />
                                            <span>Reports</span>
                                            { showReport ? <ExpandLess /> : <ExpandMore /> }
                                        </StyledButton>
                                    </span>
                                </MUITooltip>
                            )
                    }
                </Fragment>
            );
        }
    }
}

const ConnectedReportMenu = connect(state => ({
    isEntitlementExists: !!state.clapi.get('usrDetail')?.entitlements?.roles,
    isFetchingAdditionalDetails: state.searchResults.get('isFetchingAdditionalDetails'),
}))(ReportMenu);
ConnectedReportMenu.displayName = 'ReportMenu';

export default ConnectedReportMenu;
