import { call, put, takeLatest } from 'redux-saga/effects';

import {
    CONFIG_INIT,
    CONFIG_INIT_FAIL,
    CONFIG_INIT_SUCCESS,
    PUBLIC_CONFIGURATION_INIT,
    // PUBLIC_CONFIGURATION_INIT_FAIL,
    PUBLIC_CONFIGURATION_INIT_SUCCESS,
} from '../actions/config';
import { CRUX_APP_ERROR } from '../actions/errorHandler';
import ConfigApi from '../api/config';
import Bugsnag from '../bugsnag';

// -------- Get Config config details

function createConfigInitialization() {
    return function* (actions) { // eslint-disable-line consistent-return
        try {
            console.log("Crux/Init -> sagas/Config -> createConfigInitialization");
            // call the config api
            // and if the response is okay, store the returned data
            const data = yield call(ConfigApi.getConfig);
            const actionSuccess = {
                type: CONFIG_INIT_SUCCESS,
                payload: {
                    ...data,
                    hash: actions.payload.hash,
                },
            };

            // execute next redux action
            yield put(actionSuccess);
        } catch (error) {
            Bugsnag.notify(error);
            yield put({ type: CRUX_APP_ERROR, error });
            yield put({ type: CONFIG_INIT_FAIL });
        }
    };
}

function createPublicConfigurationInitialization() {
    return function* (actions) { // eslint-disable-line consistent-return
        try {
            // call the config api
            // and if the response is okay, store the returned data
            const data = yield call(ConfigApi.getPublicConfiguration);
            const actionSuccess = {
                type: PUBLIC_CONFIGURATION_INIT_SUCCESS,
                payload: {
                    ...data,
                    hash: actions.payload.hash,
                },
            };

            // execute next redux action
            yield put(actionSuccess);
        } catch (error) {
            Bugsnag.notify(error);
        }
    };
}

export const initializeConfig = createConfigInitialization();
export const initializePublicConfiguration = createPublicConfigurationInitialization();

/**
 * @function initializeConfigWatcher "listener" to the CONFIG_INIT action
 */
export function* initializeConfigWatcher() {
    yield takeLatest(CONFIG_INIT, initializeConfig);
}

/**
 * @function initializePublicConfigWatcher "listener" to the PUBLIC_CONFIGURATION_INIT action
 */
export function* initializePublicConfigurationWatcher() {
    yield takeLatest(PUBLIC_CONFIGURATION_INIT, initializePublicConfiguration);
}

/**
 * expose "listeners"
 */
export default [
    initializeConfigWatcher(),
    initializePublicConfigurationWatcher(),
];
