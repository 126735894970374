import { isAU, isNZ } from '../../constants/crux';

export default (nameSearchParams, pageContext, entryPoint, currentOwner, searchType = 'Name', exactSearch, activeView) => {
    const properties = {
        pageContext,
        searchString: '',
        searchType,
        entryPoint,
        currentOwner,
        exactSearch,
        activeView,
    };
    const _searchString = [];
    Object.keys(nameSearchParams).forEach((key) => {
        if (nameSearchParams[key] && ((isNZ && key !== 'addressState') || isAU)) _searchString.push(nameSearchParams[key]);
    });
    properties.searchString = _searchString.join(', ');
    return { ...properties, currentOwner, exactSearch };
};
