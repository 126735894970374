import { useSelector } from 'react-redux';

// This custom hook is responsible for retrieving active view from redux store.
const useActiveView = () => {
    const activeView =
        useSelector(state => state.searchResults.get('activeView'));

    return { activeView };
};

export default useActiveView;
