import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import WarningIconFilled from '@mui/icons-material/ReportProblem';
import { styled, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ClapiApi from '../../../api/clapi';
import { METADATA_PARAM } from '../../../constants/batchSearch/batchSearch';
import Commons from '../../../helpers/Commons';
import colors from '../../../constants/colors';
import MUITooltip from '../../common/MUITooltip';
import tooltipText from '../../../constants/tooltipText';
import SavedSearchHelper from '../../../helpers/search/SavedSearchHelper';
import { MAX_PROPERTIES_WITH_NOTIFICATIONS } from '../../../constants/savedSearch';

const StyledContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    minHeight: 19.92,
});

const StyledWarningIcon = styled(WarningIcon)({
    fill: colors.FLAMING_BRONZE,
    height: 12.67,
    width: 14.67,
    marginRight: 5,
    cursor: 'pointer',
});

const StyledWarningIconFilled = styled(WarningIconFilled)({
    fill: colors.FLAMING_BRONZE,
    height: 12.67,
    width: 14.67,
    marginRight: 5,
    cursor: 'pointer',
});

const StyledCount = styled(Typography)({
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '19.92px',
    letterSpacing: 0.4,
    color: colors.BLACK_87_OPAQUE,
});

const SavedSearchPropertyCount = (props) => {
    const { onLimitReached, savedSearch, isHomePage } = props;
    const [propertyCount, setPropertyCount] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const countWithComma = Commons.numberWithCommas(propertyCount);
    const isLimitReached = count => count > MAX_PROPERTIES_WITH_NOTIFICATIONS;
    const propertyWord = Commons.getPropertyLabel(propertyCount);
    const valueToDisplay = `${countWithComma} ${propertyWord}`;

    useEffect(() => {
        if (propertyCount === null) {
            const _savedSearch = JSON.parse(JSON.stringify(savedSearch));
            ClapiApi.getBatchSearch({
                [METADATA_PARAM]: true,
                ...SavedSearchHelper.sanitizeFilterRequest(_savedSearch),
            })
                .then((response) => {
                    const totalProperties = Commons.get(response, 'metadata.totalElements');
                    setPropertyCount(totalProperties);
                    onLimitReached(isLimitReached(totalProperties));
                })
                .catch(() => setPropertyCount(0))
                .finally(() => setIsLoading(false));
        }
    }, []);

    return (
        <StyledContainer>
            {isLoading
                ? <CircularProgress data-testid="property-count-loading" size={15} />
                : (
                    <Fragment>
                        {isLimitReached(propertyCount) && (
                            <MUITooltip
                                arrow
                                key="saved-search-property-count-tooltip"
                                placement="top"
                                title={tooltipText.TEXT.SAVED_SEARCH.LIMIT_REACHED}
                            >
                                { isHomePage ?
                                    <StyledWarningIconFilled data-testid="property-count-alert-filled" /> :
                                    <StyledWarningIcon data-testid="property-count-alert" />
                                }
                            </MUITooltip>
                        )}
                        <StyledCount>{valueToDisplay}</StyledCount>
                    </Fragment>
                )}
        </StyledContainer>
    );
};

SavedSearchPropertyCount.propTypes = {
    savedSearch: PropTypes.object.isRequired,
    onLimitReached: PropTypes.func.isRequired,
    isHomePage: PropTypes.bool,
};

export default SavedSearchPropertyCount;
