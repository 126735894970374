import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { routeCodes, getRoute } from '../../../../constants/routes';
import Option from '../../../../constants/options/dropdownOptions';
import { getAssetDomain } from '../../../../constants/assets';
import TOOLTIP from '../../../../constants/tooltipText';
import SearchValidation from '../SearchValidation';
import Search from '../../../../constants/rapidSearch';
import populateNameSearchFields from '../../../../helpers/nameSearch/populateOwnerSearchFields';
import RapidConstructOwnerSearchEvent from '../../../../helpers/nameSearch/RapidConstructOwnerSearchEvent';
import SEGMENT from '../../../../constants/segment';
import CurrentOwnerCheckbox from './CurrentOwnerCheckbox';
import { isAU, isNZ } from '../../../../constants/crux';
import { trackNameSearch } from '../../../../actions/segment';
import MoreActions from '../MoreActions';
import { conjunctUrlParams } from '../../../../helpers/search/ParamsBuilder';
import { invalidateCruxAppError } from '../../../../actions/errorHandler';
import FieldTooltip from '../FieldTooltip';
import LocalStorageHelper, { SEARCH_BAR_TOOLTIP_KEY } from '../../../../helpers/LocalStorageHelper';
import Commons from '../../../../helpers/Commons';
import listPropertyPreview from '../../../../constants/listPropertyPreview';
import ApiHelper from '../../../../helpers/ApiHelper';
import { saveSearchType } from '../../../../actions/componentAccess';
import { cruxLoader } from '../../../../actions/crux';
import SearchField from '../../../common/textFields/SearchField';
import StyledSearchButton from '../../../common/styledMUI/StyledSearchButton';
import ExactSearchCheckbox from '../buildingSearch/ExactSearchCheckbox';
import StateSelect from '../StateSelect';
import saveAndGetUserSetting from '../../../../helpers/userPreferences/saveAndGetUserSetting';
import { SELECTED_STATE_NAME } from '../../../../helpers/Localization';

class RapidNameSearch extends Component {
    static propTypes = {
        route: PropTypes.object.isRequired,
        dispatch: PropTypes.func,
        showPopper: PropTypes.bool,
        isCheckboxVisible: PropTypes.bool,
        clUsrId: PropTypes.string,
        activeView: PropTypes.string,
        config: PropTypes.object.isRequired,
        enableVIC: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            ownerFirstName: '',
            ownerLastName: '',
            addressState: '',
            route: null,
            isCurrentOwner: true,
            exactSearch: false,
            isLastNameFieldError: false,
            lastNameFieldErrorMessage: '',
            isStateFieldError: false,
            stateFieldErrorMessage: '',
            addressSuburb: '',
            anchorEl: [],
            open: [],
            isViewed: [],
            queryString: '',
            width: null,
        };
        this.textFieldRefs = [];
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps) {
        const { route } = this.props;
        if (route.id !== routeCodes.NAME_SEARCH_RESULT.id &&
            prevProps.route !== route) {
            this.clearAllFields();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        window.removeEventListener('resize', this.updateDimensions);
        this.clearAllFields();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {
            isStateFieldError: prevState.isStateFieldError,
            isLastNameFieldError: prevState.isLastNameFieldError,
        };
        const { location: { search } = {} } = nextProps;

        // Field Validations
        // Revert field to normal state upon receiving valid input
        if (prevState.isLastNameFieldError && prevState.ownerLastName !== '') {
            nextState.isLastNameFieldError = false;
        }
        if (prevState.isStateFieldError && prevState.addressState !== '') {
            nextState.isStateFieldError = false;
        }

        // Pre-populating nav search bar if name search has been performed
        let formattedState = {};
        const routePath = getRoute(nextProps.match.path);
        if (search && search !== prevState.queryString &&
            routePath.id === routeCodes.NAME_SEARCH_RESULT.id) {
            nextState.queryString = search;
            formattedState = populateNameSearchFields(search);
        }

        return { ...nextState, ...formattedState };
    }

    clearAllFields = () => {
        this.setState({
            ownerLastName: '',
            ownerFirstName: '',
            addressState: '',
            addressSuburb: '',
        });
    }

    validateLastName(ownerLastName) {
        // Validate whether lastName field is empty or not
        if (ownerLastName === '') {
            this.setState({
                isLastNameFieldError: true,
                lastNameFieldErrorMessage: Search.ERROR_MESSAGE.lastNameRequired,
            });
        }
    }

    validateAddressState(addressState) {
        // Validate whether selectedStateOption field is empty or not
        if (addressState === '') {
            this.setState({
                isStateFieldError: true,
                stateFieldErrorMessage: Search.ERROR_MESSAGE.stateRequired,
            });
        }
    }

    constructQueryParams = () => {
        const {
            isCurrentOwner, ownerFirstName, ownerLastName,
            addressState, addressSuburb, exactSearch,
        } = this.state;

        const formattedParams = {
            isCurrentOwner,
            exactSearch,
            ownerFirstName,
            ownerLastName,
            addressState,
            addressSuburb,
        };

        return conjunctUrlParams(formattedParams);
    };

    trackEvent = (goToPdp) => {
        const { dispatch, route: { pageContext } = {}, activeView } = this.props;
        const {
            ownerFirstName, ownerLastName, addressState,
            isCurrentOwner, addressSuburb, exactSearch,
        } = this.state;

        dispatch(trackNameSearch(RapidConstructOwnerSearchEvent(
            {
                ownerFirstName, ownerLastName, addressState, addressSuburb,
            },
            pageContext,
            SEGMENT.EVENT_PROPERTIES.TYPED,
            isCurrentOwner,
            SEGMENT.EVENT_PROPERTIES.NAME,
            exactSearch,
            !goToPdp && activeView,
        )));
    };

    redirectPage = (uri) => {
        const { history, dispatch } = this.props;
        const goToPdp = uri.includes('property');
        this.trackEvent(goToPdp);
        history.push(uri);
        if (goToPdp) {
            this.clearAllFields();
            dispatch(saveSearchType(Option.NAME));
        }
    };

    nameSearchSubmit = () => {
        const {
            isCurrentOwner, ownerFirstName, ownerLastName,
            addressState, addressSuburb, exactSearch,
        } = this.state;

        const { dispatch } = this.props;

        const ownerName = { firstName: ownerFirstName, lastName: ownerLastName };

        const listingParams = {
            isCurrentOwner: isCurrentOwner ? 'true' : 'true, false',
            exactSearch,
            state: addressState,
            addressSuburb,
            ownerName,
            dispatch,
            pathName: routeCodes.NAME_SEARCH_RESULT.staticPath,
            searchParams: this.constructQueryParams(),
        };

        this.validateLastName(ownerLastName);
        this.validateAddressState(addressState);
        // ownerLastName and addressState should not be empty before proceeding
        if (ownerLastName !== '' && (isAU ? addressState !== '' : true)) {
            if (addressState) {
                saveAndGetUserSetting(dispatch, {
                    name: SELECTED_STATE_NAME,
                    value: addressState,
                });
            }
            dispatch(cruxLoader());
            dispatch(invalidateCruxAppError());
            ApiHelper.getRedirectUrl(listingParams, this.redirectPage);
        }
    }

    toggleState = (stateName) => {
        this.setState(prevState => ({
            ...prevState,
            [stateName]: !prevState[stateName],
        }));
    };

    handleFocusPopper = (event, index) => {
        this.updateDimensions();
        if (Commons.get(LocalStorageHelper.get(SEARCH_BAR_TOOLTIP_KEY), `is-name-${index}-viewed`)) return;
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: {
                ...state.anchorEl,
                [index]: currentTarget,
            },
            open: {
                ...state.open,
                [index]: true,
            },
        }));
    };

    handleClosePopper = (event, index) => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: {
                ...state.anchorEl,
                [index]: currentTarget,
            },
            open: {
                ...state.open,
                [index]: false,
            },
            isViewed: {
                ...state.isViewed,
                [index]: true,
            },
        }));
    };

    handleClickAway = (event, index) => {
        setTimeout(() => {
            this.setState(state => ({
                open: {
                    ...state.open,
                    [index]: false,
                },
            }));
        }, 150);
    };

    handleScroll = () => {
        this.setState(prevState => ({ open: !prevState.open }));
    }

    handleStateChange = (type) => {
        this.setState({ addressState: type });
    }

    updateDimensions() {
        const newWidth = this.textFieldRefs[0];
        newWidth && this.setState({ width: newWidth.clientWidth });
    }

    render() {
        const { isCheckboxVisible, location, enableVIC } = this.props;
        const {
            isLastNameFieldError, lastNameFieldErrorMessage, isStateFieldError,
            stateFieldErrorMessage, addressState, isCurrentOwner, anchorEl, open, isViewed, width, exactSearch,
        } = this.state;
        const {
            FIRST_NAME, LAST_NAME, STATE, STATE_VIC_ENABLED, SUBURB,
        } = TOOLTIP.TEXT.NAME_SEARCH;
        const stateToolTip = enableVIC ? STATE_VIC_ENABLED : STATE;
        const stateOptions = enableVIC ? Option.STATE_OPTIONS_VIC_ENABLED : Option.STATE_OPTIONS
        const inputProps = [
            {
                field: 'ownerLastName',
                placeholder: 'Enter a Last Name *',
                onChange: event => this.setState({ ownerLastName: event.target.value }),
                tooltipTxt: LAST_NAME,
                validation: {
                    message: lastNameFieldErrorMessage,
                    onError: isLastNameFieldError,
                },
            },
            {
                field: 'ownerFirstName',
                placeholder: 'Enter a First Name',
                onChange: event => this.setState({ ownerFirstName: event.target.value }),
                tooltipTxt: FIRST_NAME,
            },
            {
                field: 'addressState',
            },
            {
                field: 'addressSuburb',
                placeholder: 'Enter a Suburb',
                onChange: event => this.setState({ addressSuburb: event.target.value }),
                tooltipTxt: SUBURB,
            },
        ];
        return (
            <div className={(!isCheckboxVisible ? 'search-inputs more-actions' : 'search-inputs')}>
                <div className="name-search-primary-fields">
                    <Grid container className="grid-container">
                        {
                            inputProps.map(({
                                field, placeholder, onChange, tooltipTxt, validation,
                            }, index) => {
                                if (field !== 'addressState') {
                                    return (
                                        <Grid key={`grid-${index}-${field}`} item className="item" xs={isAU ? 3 : 4}>
                                            <div
                                                data-testid={`${field}-container`}
                                                className={`form-input-search-field ${field}-field ${(validation && validation.onError) && ' error'}`}
                                            >
                                                <SearchField
                                                    ref={ref => this.textFieldRefs[index] = ref}
                                                    value={this.state[field]}
                                                    type="text"
                                                    id={field}
                                                    data-testid={field}
                                                    placeholder={placeholder}
                                                    onChange={onChange}
                                                    onFocus={event => this.handleFocusPopper(event, index)}
                                                    autoComplete="off"
                                                    isError={validation && validation.onError}
                                                    onBlur={event => this.handleClickAway(event, index)}
                                                    error={!!validation?.onError}
                                                    color="primary"
                                                />
                                                <FieldTooltip
                                                    id={index}
                                                    key={`name-search-popper-${index}-${field}`}
                                                    className={`${!isCheckboxVisible ? 'search-results' : ''} ${isAU ? ' col-3' : ' col-4'}`}
                                                    open={(open[index] && !isViewed[index])}
                                                    anchorEl={anchorEl[index] || null}
                                                    handleClose={event => this.handleClosePopper(event, index)}
                                                    tooltipTxt={tooltipTxt}
                                                    searchType={listPropertyPreview.SEARCH_TYPE.NAME}
                                                    width={width}
                                                />

                                                {
                                                    validation &&
                                                    <SearchValidation
                                                        message={validation.message}
                                                        onError={validation.onError}
                                                    />
                                                }

                                            </div>
                                        </Grid>
                                    );
                                }
                                return (
                                    isAU &&
                                    <StateSelect
                                        key={`${index}-${field}`}
                                        addressState={addressState}
                                        options={stateOptions}
                                        toolTipText={stateToolTip}
                                        showToolTip
                                        isStateFieldError={isStateFieldError}
                                        stateFieldErrorMessage={stateFieldErrorMessage}
                                        handleStateChange={this.handleStateChange}
                                        userSettingKey={SELECTED_STATE_NAME}
                                    />
                                );
                            })
                        }
                    </Grid>
                    {
                        isCheckboxVisible === false &&
                        <MoreActions isChecked={isCurrentOwner}>
                            <CurrentOwnerCheckbox
                                checked={isCurrentOwner}
                                onChange={() => this.toggleState('isCurrentOwner')}
                            />
                            <p className="tooltipText">{TOOLTIP.TEXT.NAME_SEARCH.OWNERNAME}</p>
                            <ExactSearchCheckbox
                                checked={exactSearch}
                                onChange={() => this.toggleState('exactSearch')}
                            />
                            <p className="tooltipText">{TOOLTIP.TEXT.BUILDING_SEARCH.EXACT_SEARCH}</p>
                        </MoreActions>
                    }
                    <StyledSearchButton
                        data-testid="name-search-button"
                        className="button-primary name-search-btn"
                        onClick={this.nameSearchSubmit}
                        disabled={location.search === `?${this.constructQueryParams()}`}
                    >
                        <img
                            src={getAssetDomain('search_icon_white.png')}
                            className="search-icon"
                            alt="name search"
                        />
                    </StyledSearchButton>
                </div>
                {
                    isCheckboxVisible &&
                    <div id="advanced-search-options" className={isNZ ? 'smaller-options-container' : ''}>
                        <CurrentOwnerCheckbox
                            checked={isCurrentOwner}
                            onChange={() => this.toggleState('isCurrentOwner')}
                        />
                        <ExactSearchCheckbox
                            checked={exactSearch}
                            onChange={() => this.toggleState('exactSearch')}
                        />
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(connect(state => ({
    activeView: state.searchResults.get('activeView'),
}))(RapidNameSearch));

