import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Favicon from 'react-favicon';
import ReduxBlockUi from 'react-block-ui/lib/redux';

import BlockUi from 'react-block-ui';
import { isAU, isNZ, REDUX_BLOCKING, REDUX_UNBLOCKING } from 'js/constants/crux';
import { CruxPageLayout } from 'js/components/layout/CruxPageLayout';
import { CruxPageLayoutOpen } from 'js/components/layout/CruxPageLayoutOpen';
import { getRoute, routeCodes } from 'js/constants/routes';

import Menu from 'js/components/global/menu/Menu';
import Footer from 'js/components/global/Footer';
import { LOGOS } from 'js/constants/assets';
import ListPropertyPreview from 'js/constants/listPropertyPreview';
import {
    LGASearch,
    SearchName,
    SearchParcel,
    SearchBuildingName,
    SavedSearch,
    SavedList,
    SuburbStats,
    NZTitlesAndDocuments,
    SavedTerritory,
    // TODO: RADIUS SEARCH - uncomment
    // RadiusSearch,
} from 'js/helpers/Entitlements';
import LocalStorageHelper, {
    MARKET_INSIGHTS_AGREEMENT_KEY,
    SEARCH_BAR_TOOLTIP_KEY,
} from 'js/helpers/LocalStorageHelper';

const JoinCrux = lazy(() => import('js/views/JoinCrux'));
const AutoLogin = lazy(() => import('js/views/AutoLogin'));
const AutoLoginLegacy = lazy(() => import('./AutoLoginLegacy'));
const LoginError = lazy(() => import('js/views/LoginError'));
const JoinUpForm = lazy(() => import('js/views/JoinUpForm'));
const LinkedAccounts = lazy(() => import('js/views/LinkedAccounts'));
const TermsAndConditions = lazy(() => import('js/views/TermsAndConditions'));
const RapidAddressSearchResultV2 = lazy(() => import('./searchResults/RapidAddressSearchResultV2'));
const RapidNameSearchResults = lazy(() => import('./searchResults/RapidNameSearchResults'));
const RapidCompanySearchResults = lazy(() => import('./searchResults/RapidCompanySearchResults'));
const RapidRadiusSearchResultV2 = lazy(() => import('./searchResults/RapidRadiusSearchResultV2'));
const RapidParcelAndLegalDescriptionSearchResults = lazy(() => import('./searchResults/RapidParcelAndLegalDescriptionSearchResults'));
const Home = lazy(() => import('./Home'));
const Property = lazy(() => import('./Property'));
const Account = lazy(() => import('js/views/Account'));
const ListReportPreview = lazy(() => import('./preview'));
const MarketInsights = lazy(() => import('./MarketInsights'));
const TitleOrdering = lazy(() => import('./TitleOrdering'));
const ErrorHandler = lazy(() => import('./ErrorHandler'));
const NotFound = lazy(() => import('./NotFound'));
const AuthenticatedRoutePing = lazy(() => import('../components/auth/AuthenticatedRoutePing'));
const RapidVolumeFolioSearchResults = lazy(() => import('./searchResults/RapidVolumeFolioSearchResults'));
const RollValuationSearchResults = lazy(() => import('./searchResults/RollValuationSearchResult'));
const RapidBuildingNameSearchResults = lazy(() => import('./searchResults/RapidBuildingNameSearchResults'));
const PropertyPrint = lazy(() => import('./PropertyPrint'));
const UserFavorites = lazy(() => import('./UserFavorites'));
const SavedSearchView = lazy(() => import('./searchResults/SavedSearch'));
const SavedListView = lazy(() => import('./searchResults/SavedList'));
const SavedTerritoryView = lazy(() => import('./searchResults/SavedTerritory'));
const SuburbStatisticsReport = lazy(() => import('./SuburbStatisticsReport'));

function App() {
    const {
        JOIN_CRUX,
        AUTO_LOGIN,
        AUTO_LOGIN_LEGACY_AU,
        AUTO_LOGIN_LEGACY_NZ,
        LOGIN_ERROR,
        JOIN_UP_FORM,
        LINKED_ACCOUNTS,
        TERMS_AND_CONDITIONS,
        HOME,
        DASHBOARD,
        PROPERTY,
        NAME_SEARCH_RESULT,
        COMPANY_SEARCH_RESULT,
        VOLUME_FOLIO_SEARCH_RESULT,
        SEARCH_RESULT,
        ACCOUNT,
        PROPERTY_LIST_REPORT,
        APPLICATION_ERROR,
        RADIUS_SEARCH_RESULT,
        MARKET_INSIGHTS,
        TITLE_ORDERING,
        PARCEL_SEARCH_RESULT,
        LEGAL_DESCRIPTION_SEARCH_RESULT,
        TITLE_REFERENCE_SEARCH_RESULT,
        PROPERTY_PRINT,
        ROLL_VALUATION_SEARCH_RESULT,
        BUILDING_SEARCH_RESULT,
        USER_FAVORITES,
        SAVED_SEARCH,
        SAVED_LIST,
        SAVED_TERRITORY,
        MULTI_LOCALITY_SEARCH,
        SUBURB_STATS_REPORT,
    } = routeCodes;

    const {
        SEARCH_TYPE: {
            NAME,
            PARCEL,
            LEGAL_DESCRIPTION,
            TITLE_REFERENCE,
            ADDRESS,
            RADIUS,
            COMPANY,
            VOLUME_FOLIO,
            ROLL_VALUATION,
            BUILDING,
        },
    } = ListPropertyPreview;

    const parcelOrLegalDescriptionRoute = isAU
        ? PARCEL_SEARCH_RESULT
        : LEGAL_DESCRIPTION_SEARCH_RESULT;

    const legacyAutoLoginRoute = isAU ? AUTO_LOGIN_LEGACY_AU : AUTO_LOGIN_LEGACY_NZ;

    useEffect(() => {
        LocalStorageHelper.remove(MARKET_INSIGHTS_AGREEMENT_KEY);
        LocalStorageHelper.remove(SEARCH_BAR_TOOLTIP_KEY);
    }, []);

    return (
        <Router>
            <Favicon url={LOGOS.FAVICON}/>
            <ReduxBlockUi
                tag="div"
                block={REDUX_BLOCKING}
                unblock={REDUX_UNBLOCKING}
                className="page"
            >
                <Suspense fallback={<BlockUi tag="div" className="suspense-blocker" blocking />}>
                    <Switch>

                        <Redirect
                            from={isAU ? routeCodes.LEGACY_RPP.path : routeCodes.LEGACY_RPNZ.path}
                            to={routeCodes.HOME.path}
                        />

                        <Route // unprotected end-point to service new user customer associations
                            exact
                            path={JOIN_CRUX.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayoutOpen
                                        history={history}
                                    >
                                        <JoinCrux
                                            match={match}
                                            history={history}
                                            location={location}
                                        />
                                    </CruxPageLayoutOpen>
                                )
                            }
                        />
                        <Route // unprotected end-point to service deep linking
                            exact
                            path={AUTO_LOGIN.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayoutOpen
                                        history={history}
                                    >
                                        <AutoLogin
                                            match={match}
                                            history={history}
                                            location={location}
                                        />
                                    </CruxPageLayoutOpen>
                                )
                            }
                        />
                        <Route // unprotected end-point to service deep linking with legacy paths
                            exact
                            path={legacyAutoLoginRoute.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayoutOpen
                                        history={history}
                                    >
                                        <AutoLoginLegacy
                                            match={match}
                                            history={history}
                                            location={location}
                                        />
                                    </CruxPageLayoutOpen>
                                )
                            }
                        />

                        <Route // unprotected end-point to service error page related to login
                            exact
                            path={LOGIN_ERROR.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayoutOpen
                                        history={history}
                                        footer={<Footer
                                            route={routeCodes.LOGIN_ERROR}
                                            history={history}
                                            location={location}
                                        />}
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                    >
                                        <LoginError
                                            match={match}
                                            history={history}
                                            location={location}
                                        />
                                    </CruxPageLayoutOpen>
                                )
                            }
                        />

                        <AuthenticatedRoutePing
                            exact
                            path={JOIN_UP_FORM.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={routeCodes.JOIN_UP_FORM}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <JoinUpForm
                                            match={match}
                                            history={history}
                                            location={location}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={LINKED_ACCOUNTS.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={routeCodes.LINKED_ACCOUNTS}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <LinkedAccounts history={history} />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        {
                            isAU &&
                            <AuthenticatedRoutePing
                                exact
                                path={TERMS_AND_CONDITIONS.path}
                                render={
                                    ({ match, history, location }) => (
                                        <CruxPageLayout
                                            header={<Menu
                                                match={match}
                                                history={history}
                                                location={location}
                                            />}
                                            footer={<Footer
                                                route={routeCodes.TERMS_AND_CONDITIONS}
                                                history={history}
                                                location={location}
                                            />}
                                            history={history}
                                        >
                                            <TermsAndConditions history={history} />
                                        </CruxPageLayout>
                                    )
                                }
                            />
                        }
                        <AuthenticatedRoutePing
                            exact
                            path={[HOME.path, DASHBOARD.path]}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        isHomePage
                                        footer={<Footer
                                            route={routeCodes.HOME}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <Home match={match} />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            path={PROPERTY_PRINT.path(':address?', ':propertyId([0-9]+)')}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={PROPERTY_PRINT}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <PropertyPrint
                                            id={PROPERTY_PRINT.id}
                                            match={match}
                                            history={history}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            path={SUBURB_STATS_REPORT.path(':locality?', ':localityId([0-9]+)', ':lgas', true)}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        permission={SuburbStats}
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={SUBURB_STATS_REPORT}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <SuburbStatisticsReport
                                            id={SUBURB_STATS_REPORT.id}
                                            match={match}
                                            history={history}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            path={PROPERTY.path(':address?', ':propertyId([0-9]+)')}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={routeCodes.PROPERTY}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <Property
                                            id={PROPERTY.id}
                                            match={match}
                                            history={history}
                                            location={location}
                                            route={routeCodes.PROPERTY}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={NAME_SEARCH_RESULT.staticPath}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        permission={SearchName}
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={NAME_SEARCH_RESULT}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <RapidNameSearchResults
                                            match={match}
                                            history={history}
                                            location={location}
                                            searchType={NAME}
                                            id={NAME_SEARCH_RESULT.id}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={COMPANY_SEARCH_RESULT.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        permission={SearchName}
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={COMPANY_SEARCH_RESULT}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <RapidCompanySearchResults
                                            match={match}
                                            history={history}
                                            location={location}
                                            searchType={COMPANY}
                                            id={COMPANY_SEARCH_RESULT.id}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={parcelOrLegalDescriptionRoute.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        permission={SearchParcel}
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={parcelOrLegalDescriptionRoute}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <RapidParcelAndLegalDescriptionSearchResults
                                            match={match}
                                            history={history}
                                            location={location}
                                            searchType={isAU ? PARCEL : LEGAL_DESCRIPTION}
                                            id={parcelOrLegalDescriptionRoute.id}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={VOLUME_FOLIO_SEARCH_RESULT.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        permission={SearchParcel}
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={VOLUME_FOLIO_SEARCH_RESULT}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <RapidVolumeFolioSearchResults
                                            match={match}
                                            history={history}
                                            location={location}
                                            searchType={VOLUME_FOLIO}
                                            id={VOLUME_FOLIO_SEARCH_RESULT.id}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            path={[MULTI_LOCALITY_SEARCH.path, SEARCH_RESULT.path(':suggestionType', ':address')]}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        permission={LGASearch}
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={getRoute(match.path)}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <RapidAddressSearchResultV2
                                            id={MULTI_LOCALITY_SEARCH.id}
                                            match={match}
                                            history={history}
                                            location={location}
                                            searchType={ADDRESS}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        {
                            isAU &&
                            <AuthenticatedRoutePing
                                path={BUILDING_SEARCH_RESULT.path}
                                render={
                                    ({ match, history, location }) => (
                                        <CruxPageLayout
                                            permission={SearchBuildingName}
                                            header={<Menu
                                                match={match}
                                                history={history}
                                                location={location}
                                            />}
                                            footer={<Footer
                                                route={BUILDING_SEARCH_RESULT}
                                                history={history}
                                                location={location}
                                            />}
                                            history={history}
                                        >
                                            <RapidBuildingNameSearchResults
                                                id={BUILDING_SEARCH_RESULT.id}
                                                match={match}
                                                history={history}
                                                location={location}
                                                searchType={BUILDING}
                                            />
                                        </CruxPageLayout>
                                    )
                                }
                            />
                        }
                        <AuthenticatedRoutePing
                            path={RADIUS_SEARCH_RESULT.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        // TODO: RADIUS SEARCH - uncomment
                                        // permission={RadiusSearch}
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={routeCodes.RADIUS_SEARCH_RESULT}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <RapidRadiusSearchResultV2
                                            id={RADIUS_SEARCH_RESULT.id}
                                            match={match}
                                            history={history}
                                            location={location}
                                            searchType={RADIUS}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={ACCOUNT.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={routeCodes.ACCOUNT}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <Account
                                            route={ACCOUNT}
                                            match={match}
                                            history={history}
                                            location={location}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={PROPERTY_LIST_REPORT.path(':searchType', ':eventTab')}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={routeCodes.PROPERTY_LIST_REPORT}
                                            history={history}
                                            location={location}
                                        />}
                                        isCopyDisabled
                                        history={history}
                                    >
                                        <ListReportPreview
                                            history={history}
                                            {...match.params}
                                            pathname={location.pathname}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={MARKET_INSIGHTS.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            marketInsightsFooter
                                            route={routeCodes.MARKET_INSIGHTS}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <MarketInsights history={history} />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        {
                            isNZ &&
                            <AuthenticatedRoutePing
                                exact
                                path={ROLL_VALUATION_SEARCH_RESULT.path}
                                render={
                                    ({ match, history, location }) => (
                                        <CruxPageLayout
                                            permission={SearchParcel}
                                            header={<Menu
                                                match={match}
                                                history={history}
                                                location={location}
                                            />}
                                            footer={<Footer
                                                route={ROLL_VALUATION_SEARCH_RESULT}
                                                history={history}
                                                location={location}
                                            />}
                                            history={history}
                                        >
                                            <RollValuationSearchResults
                                                match={match}
                                                history={history}
                                                location={location}
                                                searchType={ROLL_VALUATION}
                                                id={ROLL_VALUATION_SEARCH_RESULT.id}
                                            />
                                        </CruxPageLayout>
                                    )
                                }
                            />
                        }
                        {
                            isNZ &&
                            <AuthenticatedRoutePing
                                exact
                                path={TITLE_ORDERING.path}
                                render={
                                    ({ match, history, location }) => (
                                        <CruxPageLayout
                                            permission={NZTitlesAndDocuments}
                                            header={<Menu
                                                match={match}
                                                history={history}
                                                location={location}
                                            />}
                                            footer={<Footer
                                                marketInsightsFooter
                                                route={routeCodes.TITLE_ORDERING}
                                                history={history}
                                                location={location}
                                            />}
                                            history={history}
                                        >
                                            <TitleOrdering history={history} />
                                        </CruxPageLayout>
                                    )
                                }
                            />
                        }
                        {
                            isNZ && (
                                <AuthenticatedRoutePing
                                    exact
                                    path={TITLE_REFERENCE_SEARCH_RESULT.path}
                                    render={
                                        ({ match, history, location }) => (
                                            <CruxPageLayout
                                                permission={SearchParcel}
                                                header={<Menu
                                                    match={match}
                                                    history={history}
                                                    location={location}
                                                />}
                                                footer={<Footer
                                                    route={TITLE_REFERENCE_SEARCH_RESULT}
                                                    history={history}
                                                    location={location}
                                                />}
                                                history={history}
                                            >
                                                <RapidParcelAndLegalDescriptionSearchResults
                                                    match={match}
                                                    history={history}
                                                    location={location}
                                                    searchType={TITLE_REFERENCE}
                                                    id={TITLE_REFERENCE_SEARCH_RESULT.id}
                                                />
                                            </CruxPageLayout>
                                        )
                                    }
                                />
                            )
                        }
                        <AuthenticatedRoutePing
                            exact
                            path={USER_FAVORITES.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={USER_FAVORITES}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <UserFavorites
                                            route={USER_FAVORITES}
                                            match={match}
                                            history={history}
                                            location={location}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={SAVED_SEARCH.path(':itemId')}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        permission={SavedSearch}
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={SAVED_SEARCH}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <SavedSearchView
                                            match={match}
                                            history={history}
                                            location={location}
                                            id={SAVED_SEARCH.id}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={SAVED_LIST.path(':savedListId?')}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        permission={SavedList}
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={SAVED_SEARCH}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <SavedListView
                                            match={match}
                                            history={history}
                                            location={location}
                                            searchType={ListPropertyPreview.SEARCH_TYPE.SAVED_LIST}
                                            id={SAVED_LIST.id}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={SAVED_LIST.path(':savedListId?')}
                            permission={SavedList}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={SAVED_SEARCH}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <SavedListView
                                            match={match}
                                            history={history}
                                            location={location}
                                            searchType={ListPropertyPreview.SEARCH_TYPE.SAVED_LIST}
                                            id={SAVED_LIST.id}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={SAVED_TERRITORY.path(':itemId')}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        permission={SavedTerritory}
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={SAVED_TERRITORY}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <SavedTerritoryView
                                            key={location.key}
                                            match={match}
                                            history={history}
                                            location={location}
                                            searchType={ListPropertyPreview.SEARCH_TYPE.SAVED_TERRITORY}
                                            id={SAVED_TERRITORY.id}
                                        />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            exact
                            path={APPLICATION_ERROR.path}
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={routeCodes.APPLICATION_ERROR}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <ErrorHandler />
                                    </CruxPageLayout>
                                )
                            }
                        />
                        <AuthenticatedRoutePing
                            render={
                                ({ match, history, location }) => (
                                    <CruxPageLayout
                                        header={<Menu
                                            match={match}
                                            history={history}
                                            location={location}
                                        />}
                                        footer={<Footer
                                            route={routeCodes.NOTFOUND}
                                            history={history}
                                            location={location}
                                        />}
                                        history={history}
                                    >
                                        <NotFound location={location} />
                                    </CruxPageLayout>
                                )
                            }
                        />
                    </Switch>
                </Suspense>
            </ReduxBlockUi>
        </Router>
    );
}

export default App;
