import { Component, Suspense } from 'react';
import { useImage } from 'react-image';
import BlockUi from 'react-block-ui';
import PropTypes from 'prop-types';
import { getAssetDomain } from '../../constants/assets';

export const DefaultImg = props => (<img
    className="no-image"
    alt="Not Available"
    src={props.src}
/>);

function getNoImagePlaceholder(isThumbnail) {
    let asset = 'no-photo-available_light_1000.png';
    if (isThumbnail) {
        asset = 'no-image-placeholder@3x.png';
    }
    return getAssetDomain(asset);
}

export const Loader = ({ style }) => <BlockUi
    tag="div"
    className="loader-container"
    data-testid="image-loader"
    blocking
    style={style}
/>;
const UnLoader = (props) => {
    const { defaultUnloader, isThumbnail } = props;
    if (defaultUnloader) {
        return defaultUnloader;
    }
    return <DefaultImg src={getNoImagePlaceholder(isThumbnail)} />;
}

const CruxImg = (props) => {
    const { src, defaultUnloader, isThumbnail, ...rest } = props;
    const { src: _src, error } = useImage({
        srcList: src,
    });
    if (!_src || error) {
        return <UnLoader
            defaultUnloader={defaultUnloader}
            isThumbnail={isThumbnail}
        />;
    }
    return <img src={_src} {...rest} />;
}

// Note: We can pass a default handler that can render html
class ImageLoader extends Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
        defaultUnloader: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        isThumbnail: PropTypes.bool,
        loader: PropTypes.any,
        prospectHeight: PropTypes.number,
    }

    constructor(props) {
        super(props);
        this.state = {
            hasErrorLoadingImage: false,
        }
    }

    static getDerivedStateFromError() {
        return { hasErrorLoadingImage: true };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.src !== this.props.src) {
            this.setState({ hasErrorLoadingImage: false });
        }
    }

    render() {
        if (!this.props.src || this.state.hasErrorLoadingImage) {
            return <UnLoader
                defaultUnloader={this.props.defaultUnloader}
                isThumbnail={this.props.isThumbnail}
            />;
        }
        return (
            <Suspense
                fallback={
                    this.props.loader
                    || <Loader style={{ minHeight: this.props.prospectHeight || 82 }} />
                }
            >
                <CruxImg {...this.props} />
            </Suspense>
        )
    }
}

export default ImageLoader;
