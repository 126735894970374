import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DialogContent, DialogTitle, DialogActions, Button, Dialog, FormControlLabel, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageLoader from '../common/ImageLoader';
import SegmentConst from '../../constants/segment';
import { SmallSwitch } from '../forms/MaterialFormUi';
import { routeCodes } from '../../constants/routes';
import DistanceHelper from '../../helpers/radiusSearch/DistanceHelper';
import { trackEvent } from '../../actions/segment';
import Segment from '../../helpers/Segment';
import TOOLTIP from '../../constants/tooltipText';
import Commons from '../../helpers/Commons';
import { UriBuilder } from '../../helpers/UriBuilder';
import RadiusPickerModal from '../property/RadiusPickerModal';
import ClapiApi from '../../api/clapi';
import Tooltip from '../common/Tooltip';
import LDHelper from '../../helpers/LaunchDarklyHelper';
import { isAU } from '../../constants/crux';
import RapidSearch from '../../constants/rapidSearch';
import MuiSelect from '../searchResults/rapidSearchFiltersV2/cruxFilterDropdown/components/MuiSelect';

export class RadiusSearchModal extends Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        dispatch: PropTypes.func,
        coordinates: PropTypes.object,
        property: PropTypes.object.isRequired,
        isRadiusSearchModalOpen: PropTypes.bool,
        onCloseRadiusSearchModal: PropTypes.func.isRequired,
        setPropertyCoordinateOnMarkerUpdate: PropTypes.func.isRequired,
        usrDetail: PropTypes.object,
        userName: PropTypes.string,
        page: PropTypes.string,
        onOpenRadiusPicker: PropTypes.func,
        propertySummaryDetail: PropTypes.object,
        commons: PropTypes.object,
        activeView: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            distance: RapidSearch.RADIUS_SEARCH_DISTANCE_OPTIONS_V2[1],
            suburbOnly: false,
            activeStyle: 'normalStyle',
            isRadiusPickerModalOpen: false,
            newCoordinatesWasSet: false,
            includeHistoric: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {
            distance: prevState.distance,
            suburbOnly: prevState.suburbOnly,
            activeStyle: prevState.activeStyle,
            newCoordinatesWasSet: prevState.newCoordinatesWasSet,
        };
        DistanceHelper.distanceErrorHandler(nextState);
        if (nextProps.coordinates && !Commons.isObjectEmpty(nextProps.coordinates)) {
            nextState.newCoordinatesWasSet = true;
        }
        return nextState;
    }

    onClose = (resetDetails) => {
        if (resetDetails) {
            this.resetDetails();
        }
        this.props.onCloseRadiusSearchModal();
    };

    resetCoordinates = () => {
        this.setState({ newCoordinatesWasSet: false });
        this.props.setPropertyCoordinateOnMarkerUpdate({});
    }

    // reset state of distance and suburb only to default
    resetDetails = () => this.setState({
        distance: RapidSearch.RADIUS_SEARCH_DISTANCE_OPTIONS_V2[1],
        suburbOnly: false,
        includeHistoric: false,
    });

    renderCheckboxLabel = (field) => {
        const { SUBURB, INCLUDE_HISTORIC } = TOOLTIP.TEXT.RADIUS_SEARCH;
        let fields;
        switch (field) {
            case 'suburbOnly':
                fields = {
                    label: 'Target suburb only',
                    tooltipText: SUBURB,
                };
                break;
            case 'includeHistoric':
                fields = {
                    label: 'Include historical properties',
                    tooltipText: INCLUDE_HISTORIC,
                };
                break;
            default:
                field = null;
                break;
        }
        return (
            <Fragment>
                <div className="radius-search-description-label">{fields.label}</div>
                <Tooltip
                    tooltipTxt={fields.tooltipText}
                    tooltipId={field}
                    tooltipClass={`gray ${field}-tooltip`}
                    iconType="infoIcon"
                    tooltipPosition="bottom"
                />
            </Fragment>
        );
    };

    buildRadiusRapidSearchQueryParams() {
        const { suburbOnly, distance, includeHistoric } = this.state;
        const {
            property, coordinates = {}, includeHistoricFeature, commons,
        } = this.props;

        const propertyId = property.id || commons.propertyId;

        const paramsKey = {
            radius: distance.value,
            propertyId,
        };

        // Removing includeHistoric param when ff is off.
        if (includeHistoricFeature) {
            paramsKey.includeHistoric = includeHistoric;
        }

        // coordinates from propertyCommons
        if (property.coordinate && !Commons.isObjectEmpty(property.coordinate)) {
            const { latitude, longitude } = property.coordinate;
            paramsKey.lat = latitude;
            paramsKey.lon = longitude;
        }
        // map coordinates was set by user or via geo code
        if (coordinates && !Commons.isObjectEmpty(coordinates)) {
            paramsKey.lat = coordinates.lat;
            paramsKey.lon = coordinates.lng;
        }

        // suburb only include filter
        if (suburbOnly) {
            paramsKey.suburbOnly = true;
        }

        const params = new UriBuilder('');
        Object.keys(paramsKey).forEach(key => params.addParam(key, paramsKey[key]));

        return params.build();
    }

    toggleRadiusPickerModal = (state, callback) => {
        const { onOpenRadiusPicker } = this.props;
        if (!state) {
            this.resetDetails();
            this.resetCoordinates();
        }
        if (onOpenRadiusPicker) {
            onOpenRadiusPicker(`radiusPickerOpen-${this.props.property.id}`, state);
        }
        this.setState({
            isRadiusPickerModalOpen: state,
        });
        if (callback) {
            callback();
        }
    };

    constructRequestBody = () => {
        const {
            property,
            usrDetail,
            coordinates,
            userName,
            commons,
        } = this.props;
        return {
            propertyId: property.id || commons.propertyId,
            author: {
                name: `${Commons.get(usrDetail, 'firstName')} ${Commons.get(usrDetail, 'lastName')}`.trim(),
                email: Commons.get(usrDetail, 'emailAddress'),
                userName,
            },
            coordinate: coordinates,
        };
    }

    updatePropertyLatLong = () => {
        const requestBody = this.constructRequestBody();
        ClapiApi.updatePropertyData(requestBody)
            .catch(err => console.log((err)));
    }

    trackEventUpdateCorelogic(updateCorelogic, id) {
        const { page } = this.props;
        this.props.dispatch(trackEvent(Segment.updatePropertyLocation({
            page,
            searchType: SegmentConst.EVENT_PROPERTIES.RADIUS,
            entryPoint: SegmentConst.EVENT_PROPERTIES.RADIUS_SEARCH_MODAL,
            propertyId: id,
            panel: SegmentConst.EVENT_NAMES.UPDATE_PROPERTY_LOCATION,
            updateCorelogic,
        })));
    }

    handleSearchClick = (updateCorelogic) => {
        const {
            suburbOnly, distance, newCoordinatesWasSet, includeHistoric,
        } = this.state;
        const {
            property, dispatch, onSubmit, page, activeView,
        } = this.props;
        const coordinates = Commons.getCoordinate(property);

        if (coordinates || newCoordinatesWasSet) {
            dispatch(trackEvent(Segment.radiusSearch({
                page,
                searchType: SegmentConst.EVENT_PROPERTIES.RADIUS,
                entryPoint: SegmentConst.EVENT_PROPERTIES.RADIUS_SEARCH_MODAL,
                propertyId: property.id,
                distance: DistanceHelper.addUnit(distance.value),
                targetSuburb: suburbOnly,
                includeHistoric,
                activeView,
            })));

            // Send email to corelogic data quality for updating property location
            if (updateCorelogic) {
                this.updatePropertyLatLong();
            }
            this.onClose(true);
            const url = `${routeCodes.RADIUS_SEARCH_RESULT.path}${this.buildRadiusRapidSearchQueryParams()}`;
            window.open(url);
        } else {
            onSubmit(() => this.toggleRadiusPickerModal(true, () => this.onClose(false)));
        }

        // Send additional mixpanel event upon searching with new coordinates
        if (newCoordinatesWasSet && updateCorelogic !== undefined) {
            this.trackEventUpdateCorelogic(updateCorelogic, property.id);
        }
    };

    handleChange = (newValue) => {
        this.setState({ distance: { value: newValue.radius } });
    };

    render() {
        const {
            children,
            property,
            coordinates,
            propertyPhoto,
            propertySummaryDetail,
            isRadiusSearchModalOpen,
            setPropertyCoordinateOnMarkerUpdate,
            includeHistoricFeature,
        } = this.props;
        const {
            activeStyle, distance, isRadiusPickerModalOpen,
        } = this.state;
        const isSearchDisabled = activeStyle === 'errorStyle';
        const address = Commons.get(property, 'address.singleLine') || Commons.get(property, 'address.singleLineAddress');
        const localitySingleLine = Commons.get(property, 'address.street.locality.singleLine');
        const isTargetSuburbOnlyHidden = !localitySingleLine;

        const stateValue = RapidSearch.RADIUS_SEARCH_DISTANCE_OPTIONS_V2
            .filter(x => (x.value).toLowerCase() === `${distance.value}`.toLowerCase())[0];
        const defaultValue = stateValue ||
            { label: DistanceHelper.formatRadiusValue(distance.value), value: `${distance.value}`.toLowerCase() };
        const isHistoric = Commons.get(property, 'id') ? !Commons.get(property, 'isActiveProperty') : false;

        return (
            <Fragment>
                { children }
                <Dialog
                    open={isRadiusSearchModalOpen}
                    onClose={() => this.onClose(true)}
                    classes={{
                        scrollPaper: 'radius-search-modal-container',
                    }}
                >
                    <DialogTitle className="radius-search-modal-title">
                        <div className="radius-search-title">Radius Search</div>
                        <IconButton
                            aria-label="close"
                            onClick={() => this.onClose(true)}
                            className="closeButton"
                        >
                            <CloseIcon data-testid="close-button" className="close-button-mui" cursor="pointer" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent
                        className="radius-search-modal-body"
                    >
                        {
                            property.address &&
                            <div className="radius-search-image">
                                <ImageLoader
                                    src={Commons.get(propertyPhoto, 'mediumPhotoUrl') || Commons.get(propertySummaryDetail, 'photoList.defaultImage.mediumPhotoUrl')}
                                    alt={address}
                                    isThumbnail
                                />
                            </div>
                        }
                        <div className="radius-search-modal-details">
                            <div className="row">
                                <div className="col-md-7 col-xs-7 radius-search-description-label target-property">Target Property</div>
                            </div>
                            <div className="row radius-search-property-container">
                                <div className="col-md-12 col-xs-12 radius-search-description">{property.address && (address)}</div>
                            </div>

                            <div className="radius-search-distance-container">
                                <MuiSelect
                                    id="radius-modal"
                                    attrName="radius"
                                    options={RapidSearch.RADIUS_SEARCH_DISTANCE_OPTIONS_V2}
                                    onChange={this.handleChange}
                                    defaultValue={defaultValue}
                                    width={278}
                                    label="Select Distance"
                                    shouldUpdateInputText={event => DistanceHelper.distanceOnTypeValidation(event)}
                                    tooltipText={TOOLTIP.TEXT.RADIUS_SEARCH.DISTANCE}
                                    errorMessage={DistanceHelper.validateDistanceInput(activeStyle, distance)}
                                />
                            </div>
                            {
                                ['suburbOnly', 'includeHistoric'].map((option) => {
                                    if ((!includeHistoricFeature && option === 'includeHistoric')
                                        || (isTargetSuburbOnlyHidden && option === 'suburbOnly')) return null;
                                    return (
                                        <div
                                            className={`col-md-12 col-xs-12 row ${option}-checkbox-container`}
                                            key={option}
                                        >
                                            <FormControlLabel
                                                id={`${option}FormControl`}
                                                value="start"
                                                control={
                                                    <SmallSwitch
                                                        label={option}
                                                        input={{
                                                            onChange: () => this.setState(state => ({
                                                                [option]: !state[option],
                                                            })),
                                                        }}
                                                        style={{ color: this.state[option] ? '#39f' : '#fafafa' }}
                                                    />
                                                }
                                                label={this.renderCheckboxLabel(option)}
                                                labelPlacement="start"
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </DialogContent>
                    <DialogActions className="dialog-footer">
                        <div className="row radius-search-button-container">
                            <Button
                                className={`button-primary col-md-12 col-xs-12 radius-search-button${isSearchDisabled ? ' disabled' : ''}`}
                                onClick={() => this.handleSearchClick()}
                                disabled={isSearchDisabled}
                                variant="contained"
                            >
                                <span>Search</span>
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
                {
                    !Commons.isObjectEmpty(property) &&
                    <RadiusPickerModal
                        open={isRadiusPickerModalOpen}
                        address={property.address && (address)}
                        coordinates={coordinates}
                        onClose={state => this.toggleRadiusPickerModal(state)}
                        setPropertyCoordinateOnMarkerUpdate={setPropertyCoordinateOnMarkerUpdate}
                        triggerSearch={this.handleSearchClick}
                        resetCoordinates={this.resetCoordinates}
                        isHistoric={isHistoric}
                    />
                }
            </Fragment>
        );
    }
}
const ConnectedRadiusSearchModal = connect(state => ({
    usrDetail: state.clapi.get('usrDetail'),
    userName: state.claud.get('user')?.userName,
    propertyPhoto: state.clapi.get('propertyPhoto'),
    includeHistoricFeature: isAU && LDHelper
        .featureFlagSelector(state, 'crux.historic.property.data', false),
    commons: state.crux.get('property').get('commons'),
    activeView: state.searchResults.get('activeView'),
}))(RadiusSearchModal);
ConnectedRadiusSearchModal.displayName = 'RadiusSearchModal';
export default ConnectedRadiusSearchModal;
