const conjunct = (items, conjunction = '&', separator = ',') => {
    const listStart = items.slice(0, -1).join(`${separator} `);
    const listEnd = items.slice(-1);

    if (items.length > 2) {
        return [listStart, listEnd].join(`${separator} ${conjunction} `);
    } else if (items.length === 2) {
        return [listStart, listEnd].join(` ${conjunction} `);
    }
    return [listStart, listEnd].join('');
};

const isEqualIgnoreCase = (str, strToCompare) => str.toUpperCase() === strToCompare.toUpperCase();
const isValid = str => typeof str === 'string' || str instanceof String;

export default {
    conjunct,
    isEqualIgnoreCase,
    isValid,
};
