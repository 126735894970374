import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import moment from 'moment';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import BlockUi from 'react-block-ui';
import UpdateAttributeFields, {
    getPropertyType,
    renderField,
    renderSelect,
} from '../attribute/UpdateAttributeFields';
import ClapiApi from '../../../api/clapi';
import style from '../../../constants/dropdownStyle';
import Commons from '../../../helpers/Commons';
import DropdownSelect from '../../common/DropdownSelect';
import CalendarDatePicker from '../../common/rc-calendar/CalendarDatePicker';
import { CLAPI_GET_INTELLIVAL_VALUATION_AVM_FAIL } from '../../../actions/clapi';
import { trackEvent } from '../../../actions/segment';
import Segment from '../../../helpers/Segment';
import SEGMENT from '../../../constants/segment';
import PropertyUpdateHelper from '../../../helpers/PropertyUpdateHelper';
import LiveIntellivalAvmEstimateRequestBody from '../../../model/LiveIntellivalAvmEstimateRequestBody';
import ValuationHelper from '../../../helpers/ValuationHelper';
import PanelError from '../../common/PanelError';
import { PANEL, VALUATION } from '../../../constants/propertyDetail';
import PropertyDetailCommonsResponseBody from '../../../model/PropertyDetailCommonsResponseBody';
import FileHelper from '../../../helpers/FileHelper';
import ValuationRange from './ValuationRange';

const getSaleDate = (propertyCommons) => {
    const state = Commons.get(propertyCommons, 'location.state');
    const lastSale = Commons.get(propertyCommons, 'lastSale');
    let saleDate;
    if (lastSale) {
        saleDate = state === 'SA' ? lastSale.settlementDate : lastSale.contractDate;
        saleDate = Commons.format(moment(saleDate), 'DD MMM YYYY');
    }
    return saleDate;
};

const getSalePrice = (propertyCommons) => {
    const lastSale = Commons.get(propertyCommons, 'lastSale');
    return lastSale ? `$${Commons.numberWithCommas(lastSale.price)}` : undefined;
};

const errorsInitialValue = () => ({
    bedrooms: null,
    bathrooms: null,
    carSpaces: null,
    landArea: null,
    floorArea: null,
    yearBuilt: null,
    salePrice: null,
});

const customOptions = [
    { label: 'For modelling purposes only', value: 'model' },
    { label: 'Current and accurate', value: 'accurate' },
];

const renderSaleDate = props => (
    <DropdownSelect
        id="saleDate"
        customClassName="sale-date-field"
        classNameForEvent="rc-calendar"
        value={props.input.value ? Commons.format(moment(props.input.value), 'DD MMM YYYY') : null}
    >
        <CalendarDatePicker
            value={props.input.value ? moment(props.input.value) : null}
            onSelect={props.input.onChange}
            allowFutureDate={false}
        />
    </DropdownSelect>
);

export class LiveAvmModal extends Component {
    static propTypes = {
        propertyCommons: PropTypes.object,
        isLiveAvmModalOpen: PropTypes.bool,
        closeLiveAvmModal: PropTypes.func,
        valuation: PropTypes.object,
        componentError: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: errorsInitialValue(),
            focusedField: {
                label: null,
                field: null,
            },
            valuation: props.valuation || null,
            initialValues: props.initialValues,
            isApiSuccessful: true,
            isReportSuccessful: true,
            isFetching: false,
            isFetchingReport: false,
            apiError: null,
            isDownloadable: false,
        };
    }

    setFocusedField = ({ label, field }) => {
        this.setState({ focusedField: { label, field } });
    };

    setErrors = (field, error) => {
        this.setState(state => ({
            errors: {
                ...state.errors,
                [field]: error,
            },
        }));
    };

    getCustomStyles = () => ({
        indicatorSeparator: styles => ({
            ...styles,
            display: 'none',
        }),
        control: (styles, {
            isFocused,
        }) => ({
            ...styles,
            marginTop: '9px',
            height: '37px',
            width: '223px',
            borderRadius: '2px',
            boxShadow: 'none',
            borderColor: isFocused ?
                style.normalStyle.borderColorOnFocus :
                style.normalStyle.borderColor,
            backgroundColor: isFocused ?
                style.normalStyle.backgroundColorOnFocus :
                style.normalStyle.backgroundColor,
            '&:hover': {
                borderColor: style.normalStyle.borderColorOnHover,
            },
        }),
        menu: styles => ({
            ...styles,
            width: '223px',
        }),
        menuList: styles => ({
            ...styles,
            width: '223px',
            maxHeight: 'calc((100vh - 540px) / 2)',
            minHeight: '45px',
        }),
    });

    getLiveAvmRequestBody(formValues) {
        const {
            saleDate,
            salePrice,
            yearBuilt,
            ...attributes
        } = formValues || {};
        const requestBody = new LiveIntellivalAvmEstimateRequestBody();
        requestBody.bathrooms = attributes.bathrooms && Number(attributes.bathrooms);
        requestBody.bedrooms = attributes.bedrooms && Number(attributes.bedrooms);
        requestBody.carSpaces = attributes.carSpaces && Number(attributes.carSpaces);
        requestBody.floorAreaM2 = attributes.floorArea && Number(attributes.floorArea);
        requestBody.landAreaM2 = attributes.landArea && Number(attributes.landArea);
        requestBody.propertyType = attributes.propertyType?.value;
        requestBody.saleDate = Commons.formatDate(saleDate, 'YYYY-MM-DD', 'DD MMM YYYY');
        requestBody.salePrice = salePrice && Number(salePrice.replace(/[^0-9]+/g, ''));
        requestBody.yearBuilt = yearBuilt;
        return requestBody;
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') e.preventDefault();
    };

    normalizePrice = (v) => {
        const clean = v.replace(/[$,]/g, '');
        let error = null;
        if (!Commons.isPositiveNumber(clean)) {
            error = 'Must be a number';
        } else if (!Commons.isZeroToHundredMillion(clean)) {
            error = 'Please enter a value between 0 and 100000000';
        }
        this.setErrors('salePrice', error);
        if (v) {
            return !!error ? v : `$${Commons.numberWithCommas(clean)}`;
        }
        return v;
    };

    handleYearBuiltChange = (e) => {
        const { value } = e.target;
        const maxYear = new Date().getFullYear();
        let error = null;
        const minYear = 1800;

        if (value > maxYear) {
            error = 'Cannot be a future year';
        } else if (value && value < minYear) {
            error = 'Year must be greater than 1800';
        } else if (!Commons.isPositiveNumber(value)) {
            error = 'Must be a number';
        }

        this.setErrors('yearBuilt', error);
    };

   downloadReport = () => {
       const { formValues, propertyCommons } = this.props;
       const singleLineAddress = Commons.get(propertyCommons, 'location.singleLine');
       const requestBody = this.getLiveAvmRequestBody(formValues);

       this.setState({
           isFetchingReport: true,
       });
       this.sendValuationMixpanel(true);

       ClapiApi.getLiveIntellivalAvmReport(propertyCommons.propertyId, requestBody)
           .then((resp) => {
               if (resp && resp.url) {
                   fetch(resp.url).then((response) => {
                       response.blob().then((data) => {
                           FileHelper.downloadBlob(data,
                               `Valuation_Estimate_AVM_${singleLineAddress.replace(/ /g, '_')}_${Commons.format(moment(), 'DD-MM-YYYY')}`)

                           this.setState({
                               isReportSuccessful: true,
                               isFetchingReport: false,
                           });
                       });
                   });
               } else {
                   this.downloadReportError();
               }
           })
           .catch(() => {
               this.downloadReportError();
           });
   }

    downloadReportError = () => {
        this.setState({
            isReportSuccessful: false,
            isFetchingReport: false,
            apiError: VALUATION.DOWNLOAD_VALUATION_ERROR_MSG,
        });
    }

    sendValuationMixpanel(isDownloadReport) {
        const {
            dispatch,
            propertyCommons: { propertyId },
            formValues,
        } = this.props;

        const confirmation = formValues && formValues.confirmation;

        const mixpanel = {
            eventName: SEGMENT.EVENT_NAMES.UPDATE_VALUATION_ESTIMATE,
            reportFormat: {},
        };

        if (isDownloadReport) {
            mixpanel.eventName = SEGMENT.EVENT_NAMES.DOWNLOAD_VALUATION_ESTIMATE_REPORT;
            mixpanel.reportFormat = {
                label: SEGMENT.EVENT_PROPERTIES.REPORT_FORMAT,
                value: SEGMENT.EVENT_PROPERTIES.VALUATION_ESTIMATE_REPORT_FORMAT,
            };
        }

        dispatch(trackEvent(Segment.updateValuation({
            ...mixpanel,
            page: SEGMENT.EVENT_PROPERTIES.PROPERTY_DETAIL,
            entryPoint: SEGMENT.EVENT_PROPERTIES.VALUATION_ESTIMATE_PANEL,
            panel: SEGMENT.EVENT_PROPERTIES.VALUATION_ESTIMATE_REPORT_MODAL,
            propertyId,
            objectContext: confirmation?.label,
        })));
    }

    handleGetValuation = () => {
        const {
            formValues: {
                confirmation,
                yearBuilt,
                salePrice,
                saleDate,
                ...propertyAttributes
            } = {},
            initialValues: {
                confirmation: initialConfirmation,
                yearBuilt: initialYearBuilt,
                salePrice: initialSalePrice,
                saleDate: initialSaleDate,
                ...initialPropertyAttributes
            } = {},
            formValues,
            usrDetail,
            propertyCommons: { propertyId },
        } = this.props;
        const requestBody = this.getLiveAvmRequestBody(formValues);
        this.sendValuationMixpanel();
        this.setState({
            isFetching: true,
            isDownloadable: false,
        });
        if (Commons.isEqual(confirmation, customOptions[1])) {
            const additionalFields = [];
            if (initialYearBuilt !== yearBuilt) {
                additionalFields.push({ label: 'Year Built', value: yearBuilt });
            }
            // send property update request service
            ClapiApi.updatePropertyData(PropertyUpdateHelper.buildPropertyUpdateRequestBody({
                initialValues: initialPropertyAttributes,
                formValues: propertyAttributes,
                property: { id: propertyId },
                usrDetail,
                additionalFields,
            }));
        }
        ClapiApi.getLiveIntellivalAvmEstimate(propertyId, requestBody)
            .then((response) => {
                if (!response.errors) {
                    const valuation = ValuationHelper.buildValuationRangeProps(response);
                    this.setState({
                        valuation,
                        initialValues: formValues,
                        isApiSuccessful: true,
                        isDownloadable: true,
                        isFetching: false,
                    });
                } else {
                    this.setState({
                        valuation: { avmHasDetails: false },
                        initialValues: formValues,
                        isApiSuccessful: true,
                        isDownloadable: false,
                        isFetching: false,
                    });
                }
            })
            .catch((error) => {
                const { response } = error;
                let _apiError = null;
                if (response && response.errors && response.errors.length > 0) {
                    const propertyTypeError = response.errors.filter(_error => /propertyType+/g.test(_error.msg));
                    if (propertyTypeError.length > 0) {
                        _apiError = VALUATION.PROPERTY_TYPE_NOT_ACCEPTED;
                    }
                }
                this.setState({
                    valuation: { avmHasDetails: false },
                    initialValues: formValues,
                    isApiSuccessful: false,
                    isDownloadable: false,
                    isFetching: false,
                    apiError: _apiError,
                });
            });
    };

    renderAdditionalFields() {
        const { errors, focusedField } = this.state;
        const errorMsg = focusedField.label && errors[focusedField.field]
            ? `${focusedField.label}: ${errors[focusedField.field]}`
            : '';
        const yearBuiltField = 'yearBuilt';
        const salePriceField = 'salePrice';
        const displayError = focusedField.field === yearBuiltField
            || focusedField.field === salePriceField;
        return (
            <Fragment>
                <div className="additional-fields-container">
                    <div
                        className="sale-fields-container"
                        data-testid="sale-fields-container"
                        key={yearBuiltField}
                    >
                        <span className="sale-fields-label">Year Built</span>
                        <Field
                            datatestid={yearBuiltField}
                            name={yearBuiltField}
                            id={yearBuiltField}
                            key={yearBuiltField}
                            component={renderField}
                            type="text"
                            className={`form-control year-built-field${errors[yearBuiltField] ? ' has-error' : ''}`}
                            onKeyPress={this.handleKeyPress}
                            maxLength="4"
                            onChange={this.handleYearBuiltChange}
                            onFocus={() => this.setFocusedField({ label: 'Year Built', field: yearBuiltField })}
                            onBlur={() => this.setFocusedField({ label: null, field: null })}
                        />
                    </div>
                    <div
                        className="sale-fields-container"
                        data-testid="sale-fields-container"
                        key="saleDate"
                    >
                        <span className="sale-fields-label">Last Sale Date</span>
                        <Field
                            name="saleDate"
                            id="saleDate"
                            key="saleDate"
                            className="form-control sale-date-field"
                            component={renderSaleDate}
                        />
                    </div>
                    <div
                        className="sale-fields-container"
                        data-testid="sale-fields-container"
                        key={salePriceField}
                    >
                        <span className="sale-fields-label">Last Sale Price</span>
                        <Field
                            datatestid={salePriceField}
                            name={salePriceField}
                            id={salePriceField}
                            key={salePriceField}
                            component={renderField}
                            type="text"
                            className={`form-control sale-price-field${errors[salePriceField] ? ' has-error' : ''}`}
                            onKeyPress={this.handleKeyPress}
                            maxLength="12"
                            normalize={this.normalizePrice}
                            onFocus={() => this.setFocusedField({ label: 'Sale Price', field: salePriceField })}
                            onBlur={() => this.setFocusedField({ label: null, field: null })}
                        />
                    </div>
                    <div
                        className="sale-fields-container"
                        data-testid="sale-fields-container"
                        key="construction"
                    >
                        {/* container for quality of constuction used for width for now */}
                        <div className="construction-field" />
                    </div>
                </div>
                <p className="text-danger mb-0 error-message">{displayError && errorMsg}</p>
            </Fragment>

        );
    }

    render() {
        const {
            isLiveAvmModalOpen,
            closeLiveAvmModal,
            componentError,
            formValues: {
                confirmation,
                ...propertyAttributes
            } = {},
        } = this.props;
        const {
            errors, focusedField, valuation, isApiSuccessful, apiError,
            isDownloadable,
            initialValues: {
                confirmation: initialConfirmation,
                ...initialPropertyAttributes
            } = {},
            isFetching,
            isFetchingReport,
            isReportSuccessful,
        } = this.state;
        const pristineAttributes = Commons.isEqual(propertyAttributes, initialPropertyAttributes);
        const pristineConfirmation = Commons.isEqual(confirmation, initialConfirmation);
        const hasError = Commons.isEqual(errors, errorsInitialValue());
        const confirmationInInitState = confirmation && confirmation.value === '';
        /**
         * disable get valuation when any are satisfied:
         * - api is fetching
         * - api is not fetching and no changes/errors on field inputs
         * - api is not fetching and is downloadable and no changes/errors on field inputs
         * @type {boolean}
         */
        let disableGetValuation = true;
        if (!confirmationInInitState) {
            disableGetValuation = !(!isFetching && hasError && (!pristineConfirmation || !pristineAttributes));
        }
        let message = VALUATION.VALUATION_ESTIMATE_UNAVAILABLE;
        if (componentError) {
            message = null; // to display the panel default error message, use the panel props
        } else if (valuation && valuation.confidenceTooLow) {
            message += `<br />${VALUATION.CONFIDENCE_TOO_LOW}`;
        } else if (!isApiSuccessful) {
            message = apiError;
        }
        return (
            <Dialog
                open={isLiveAvmModalOpen}
                classes={{
                    root: 'modal-bg-opacity live-avm-modal-container',
                    paper: 'scroll-body',
                }}
                data-testid="live-avm-modal"
            >
                <DialogTitle className="live-avm-dialog-title">
                    <div className="modal-title-wrapper">
                        <div className="live-avm-title">Valuation Estimate Report</div>
                        <CloseIcon
                            data-testid="close-button-mui"
                            className="close-button-mui"
                            onClick={closeLiveAvmModal}
                        />
                    </div>
                </DialogTitle>
                <DialogContent className="live-avm-modal-body">
                    <form data-testid="liveAvmForm" className="live-avm-modal-body-container" name="liveAvmForm">
                        {
                            UpdateAttributeFields({
                                errors,
                                setFocusedField: this.setFocusedField,
                                setErrors: this.setErrors,
                                focusedField,
                                isValuation: true,
                            })
                        }
                        {this.renderAdditionalFields()}
                        <BlockUi blocking={isFetching} renderChildren={false}>
                            {
                                !valuation.avmHasDetails ?
                                    <PanelError
                                        data-testid="panel-error"
                                        message={message}
                                        panel={PANEL.VALUATION_ESTIMATE}
                                    />
                                    : <ValuationRange {...valuation} />
                            }
                        </BlockUi>
                        <div className="live-avm-footer">
                            <div className="confirmation-container">
                                <span data-testid="confirmation-label" className="confirmation-label">I confirm these attributes are </span><span className="form-required">*</span>
                                <div data-testid="confirmation">
                                    <Field
                                        label="confirmation"
                                        name="confirmation"
                                        id="confirmation"
                                        key="confirmation"
                                        component={renderSelect}
                                        customOptions={customOptions}
                                        customStyles={this.getCustomStyles()}
                                    />
                                </div>
                            </div>
                            <div className="valuation-container">
                                {
                                    !isReportSuccessful ? // Download report error display
                                        <div className="error-container">
                                            <span className="text-right text-danger">{ apiError }</span>
                                        </div> : null
                                }
                                <div className="button-container">
                                    <Button
                                        variant="contained"
                                        className="button-primary update-valuation-button"
                                        onClick={this.handleGetValuation}
                                        disabled={disableGetValuation}
                                    >
                                        <span>Update Valuation</span>
                                    </Button>
                                    <BlockUi blocking={isFetchingReport} renderChildren={false}>
                                        <Button
                                            variant="contained"
                                            className="button-primary download-button"
                                            disabled={!isDownloadable || (isDownloadable && !pristineAttributes) || valuation.confidenceTooLow}
                                            onClick={this.downloadReport}
                                        >
                                            <span>Download</span>
                                        </Button>
                                    </BlockUi>
                                </div>
                                <div data-testid="license-agreement-text" className="license-agreement-text">By selecting Download you agree to CoreLogic’s&nbsp;
                                    <a href="https://www.corelogic.com.au/about-us/copyright-disclaimer" target="_blank" rel="noreferrer noopener" role="button" aria-haspopup="true">
                                        Disclaimer
                                    </a>
                                </div>
                            </div>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}

const ConnectedLiveAvmModal = connect((state) => {
    const propertyCommons = PropertyDetailCommonsResponseBody
        .parse(state.crux.get('property').get('commons'));

    return {
        initialValues: {
            propertyType: getPropertyType({
                property: {
                    propertyType: Commons.get(propertyCommons, 'attrCore.propertyType'),
                },
            }),
            bedrooms: Commons.get(propertyCommons, 'attrCore.beds'),
            bathrooms: Commons.get(propertyCommons, 'attrCore.baths'),
            carSpaces: Commons.get(propertyCommons, 'attrCore.carSpaces'),
            landArea: Commons.get(propertyCommons, 'attrCore.landArea'),
            floorArea: Commons.get(propertyCommons, 'attrAdditional.floorArea'),
            yearBuilt: Commons.get(propertyCommons, 'attrAdditional.yearBuilt'),
            salePrice: getSalePrice(propertyCommons),
            saleDate: getSaleDate(propertyCommons),
            confirmation: { label: 'Please select an option', value: '' },
        },
        formValues: getFormValues('liveAvmForm')(state),
        componentError: state.errorHandler.get('componentErrors').get(CLAPI_GET_INTELLIVAL_VALUATION_AVM_FAIL),
        usrDetail: state.clapi.get('usrDetail'),
        propertyCommons,
    };
})(reduxForm({
    form: 'liveAvmForm',
    enableReinitialize: true,
})(LiveAvmModal));
ConnectedLiveAvmModal.displayName = 'LiveAvmModal';
export default ConnectedLiveAvmModal;
