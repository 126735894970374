import { Map as IMap } from 'immutable';
import { REHYDRATE } from 'redux-persist';
import { LAInitStatus, LA_ACTION } from 'js/actions/linkedAccounts';
import { SET_TNC_ACCEPTED_STATUS } from 'js/actions/userPreferences';
import { CRUX_POST_JOIN_SUCCESS } from 'js/actions/crux';

export const initialState = IMap({
    listedAccount: [],
    selectedAccount: '',
    selectedClAppAccountUserGuid: '',
    initializeStatus: LAInitStatus.UNINIT,
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LA_ACTION.FETCH_ACCOUNTS_SUCCESS:
            let selectedAccount = state.get('selectedAccount');
            let selectedClAppAccountUserGuid = state.get('selectedClAppAccountUserGuid');
            const { listedAccount, tncPermanentlyAccepted, postJoinInProgress } = action.payload;
            const tncCurrentlyAccepted = !!state.get('tncCurrentlyAccepted');
            if (listedAccount.length === 1) {
                selectedAccount = listedAccount[0].id;  // clAppAccountGuid
                selectedClAppAccountUserGuid = listedAccount[0].clAppAccountUserGuid;
            }
            return state.merge(
                IMap({
                    listedAccount,
                    selectedAccount,
                    selectedClAppAccountUserGuid,
                    tncPermanentlyAccepted,
                    tncCurrentlyAccepted,
                    postJoinInProgress,
                    initializeStatus: LAInitStatus.SUCCESS,
                })
            );
        case LA_ACTION.FETCH_ACCOUNTS_FETCHING:
            return state.merge(
                IMap({
                    initializeStatus: LAInitStatus.FETCHING,
                })
            );
        case LA_ACTION.FETCH_ACCOUNTS_FAILED:
            return state.merge(
                IMap({
                    initializeStatus: LAInitStatus.FAIL,
                })
            );
        case LA_ACTION.RESET_SELECTED_CL_APP_ACCOUNT_USER_GUID:
            return state.merge(
                IMap({
                    selectedAccount: '',
                    selectedClAppAccountUserGuid: '',
                })
            );
        case LA_ACTION.SELECT_ACCOUNT:
            return state.merge(
                IMap({
                    selectedAccount: action.payload.id, // clAppAccountGuid
                    selectedClAppAccountUserGuid: action.payload.clAppAccountUserGuid,
                })
            );
        case SET_TNC_ACCEPTED_STATUS:
            return state.merge(
                IMap({
                    tncPermanentlyAccepted: action.payload.tncPermanentlyAccepted,
                    tncCurrentlyAccepted: action.payload.tncCurrentlyAccepted,
                })
            );
        case CRUX_POST_JOIN_SUCCESS:
            return state.merge(
                IMap({
                    postJoinInProgress: null,
                })
            );
        case REHYDRATE:
            if (action.payload) {
                return state.merge(action.payload.linkedAccounts);
            }
            return state.merge(initialState);
        default:
            return state;
    }
}
