export default {
    SAVED_LISTS: 'crux-user-saved-lists',
    SAVED_SEARCH: 'crux.saved.search',
    RP_PROPOSALS_REPORT: 'rp.proposals.report',
    NEARMAP: 'nearmap.type',
    PROPERTY_INVESTMENT_REPORT: 'crux-property-investment-report',
    DIGITAL_PROFILE_REPORT: 'crux.digital.profile.report',
    FORMS_INTEGRATION: 'crux-rei-forms',
    NZ_MAP_LAYER_FLOOD: 'pg.flood.layer',
};
