import { take, fork, call, put } from 'redux-saga/effects';

import { getUserDetail } from './clapi';
import { getRecentAddressSearches, getRecentParcelSearches, getRecentTitleSearches } from './msgApi';
import {
    getRecentAddressSearches as getRecentAddressSearchesAction,
    getRecentParcelSearches as getRecentParcelSearchesAction,
    getRecentTitleSearches as getRecentTitleSearchesAction,
} from '../actions/msgApi';
import { CONFIG_INIT_SUCCESS } from '../actions/config';
import { LD_INIT } from '../actions/launchDarkly';
import { SEGMENT_INIT } from '../actions/segment';
import { initializeLD } from './launchDarkly';
import { initializeSegment } from './segment';
import { getNewUserActivityFeeds as getNewUserActivityFeedsAction } from '../actions/favourites';
import { getNewUserActivityFeeds as getNewUserActivityFeedsSaga } from './favourites';
import { isNZ } from '../constants/crux';

export function* clapiInit() {
    console.log("Crux/Init -> sagas/Init -> clapiInit");

    // Get recent address
    yield fork(getRecentAddressSearches, getRecentAddressSearchesAction());
    yield fork(getRecentParcelSearches, getRecentParcelSearchesAction());
    if (isNZ) {
        yield fork(getRecentTitleSearches, getRecentTitleSearchesAction());
    }

    // favourites
    yield fork(getNewUserActivityFeedsSaga, getNewUserActivityFeedsAction());
}

export function* getClapiUserAndLdSegment() {
    console.log("Crux/Init -> sagas/Init -> getClapiUserAndLdSegment");

    yield call(getUserDetail); // eventually this will call UserPreferences.getUserSummary()

    // launchDarkly
    yield put({ type: LD_INIT });
    yield fork(initializeLD);

    // segment init
    yield put({ type: SEGMENT_INIT });
    yield fork(initializeSegment);
}

export function* appInit() {
    // Block generator util CONFIG_INIT_SUCCESS is dispatched
    yield take(CONFIG_INIT_SUCCESS);
    console.log("Crux/Init -> sagas/Init -> appInit -> CONFIG_INIT_SUCCESS received");

    yield fork(clapiInit);
    yield fork(getClapiUserAndLdSegment);
}

export default [
    appInit(),
];
