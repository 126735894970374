import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import ClapiApi from '../../../../api/clapi';
import Commons from '../../../../helpers/Commons';
import ReportHelper from '../../../../helpers/report/ReportHelper';
import Constant from '../../../../constants/constant';
import { isAU } from '../../../../constants/crux';

export default function withValuationAndRentalReportsHandler(WrappedComponent) {
    class ComponentWrapper extends Component {
        static propTypes = {
            property: PropTypes.object.isRequired,
        };

        constructor(props) {
            super(props);
            this.state = {
                isFetching: false,
                avmDetails: null,
                rentalAvm: null,
            };
        }

        handleOnClick = () => {
            const {
                property,
            } = this.props;
            const { isFetching, avmDetails, rentalAvm } = this.state;
            if (!isFetching && !avmDetails && !rentalAvm) {
                this.setState({ isFetching: true });
                const promises = [];
                // valuation details
                promises.push(ClapiApi.getIntellivalValuationAvm(
                    property.id,
                ).catch(() => ({ status: 'error' })));
                // rental details
                promises.push(ClapiApi.getRentalAvm(property.id).catch(() => ({ status: 'error' })));
                Promise.all(promises)
                    .then((res) => {
                        if (res) {
                            const [valuation, rental] = res;
                            valuation.source = Constant.Intellival.Source;
                            this.setState(() => ({
                                isFetching: false,
                                avmDetails: valuation,
                                rentalAvm: rental,
                            }));
                        }
                    });
            }
        };

        render() {
            const {
                property, children,
            } = this.props;
            const { rentalAvm, avmDetails } = this.state;
            const [triggerChild, ...restChildren] = Children.toArray(children);
            const triggerWithHandler = React.cloneElement(triggerChild, {
                onClick: () => {
                    if (triggerChild.props.onClick) {
                        triggerChild.props.onClick();
                    }
                    if (isAU) {
                        this.handleOnClick();
                    }
                },
            });
            const state = Commons.extractStateFromSL(Commons.get(property, 'address.singleLine'));
            const displayRentalEstimate = ((rentalAvm && rentalAvm.rentalAvmDetail)
                && ReportHelper.isRentalEstimateToBeDisplayed(rentalAvm.rentalAvmDetail)) || false;
            const displayAvmDetails = ReportHelper.isAVMDetailToBeDisplayed({ avmDetails });
            return (
                <WrappedComponent
                    {...this.props}
                    displayRentalEstimate={displayRentalEstimate}
                    displaySOIReport={state === 'VIC'}
                    blocking={this.state.isFetching}
                    displayAvmDetails={displayAvmDetails}
                    avmDetails={avmDetails}
                >
                    { triggerWithHandler }
                    { restChildren }
                </WrappedComponent>
            );

        }
    }

    return ComponentWrapper;
}
