import { Autocomplete, styled } from '@mui/material';
import Colors from '../../../constants/colors';

const StyledAutoComplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiInputLabel-root': {
        color: Colors.BLACK_60_OPAQUE,
        fontSize: 14.857,
    },
    '&.Mui-focused[aria-owns="sort-auto-complete-popup"] fieldset': {
        borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused[aria-owns="sort-auto-complete-popup"] .MuiOutlinedInput-input': {
        color: theme.palette.primary.main,
    },
    '& + .MuiAutocomplete-popper': {
        zIndex: '99',
        '& .MuiPaper-root': {
            marginTop: '5px',
        },
        '& .MuiAutocomplete-listbox': {
            cursor: 'default',
            '& .MuiAutocomplete-option': {
                fontSize: '14px',
                paddingLeft: '11.5px',
                paddingRight: '11.5px',
                color: Colors.BLACK_60_OPAQUE,
                '&[aria-selected="true"]': {
                    color: Colors.BLACK_87_OPAQUE,
                    backgroundColor: 'transparent !important',
                    cursor: 'default',
                },
                '&[aria-disabled="true"]': {
                    color: Colors.BLACK_30_OPAQUE,
                    opacity: 1,
                    cursor: 'default',
                },
            },
        },
    },
    '& .MuiAutocomplete-endAdornment .MuiButtonBase-root:hover': {
        backgroundColor: 'transparent',
    },
    '& .MuiInputAdornment-positionStart': {
        marginRight: 0,
        '& > div': {
            display: 'flex',
        },
    },
    '& .MuiInputBase-root': {
        '&.Mui-disabled': {
            cursor: 'not-allowed',
            '&:hover': {
                fieldset: {
                    borderColor: Colors.BLACK_30_OPAQUE,
                },
            },
            '& .MuiInputBase-input': {
                cursor: 'not-allowed',
            },
        },
    },
}));

export default StyledAutoComplete;
