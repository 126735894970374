export const LAInitStatus = {
    UNINIT: 'LA_UNINIT',
    FETCHING: 'LA_FETCHING',
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
};

export const LA_ACTION = {
    FETCH_ACCOUNTS_FETCHING: 'LA_ACTION_FETCH_ACCOUNTS_FETCHING',
    FETCH_ACCOUNTS_SUCCESS: 'LA_ACTION_FETCH_ACCOUNTS_SUCCESS',
    FETCH_ACCOUNTS_FAILED: 'LA_ACTION_FETCH_ACCOUNTS_FAILED',
    SELECT_ACCOUNT: 'LA_ACTION_SELECT_ACCOUNT',
    RESET_SELECTED_CL_APP_ACCOUNT_USER_GUID: 'RESET_SELECTED_CL_APP_ACCOUNT_USER_GUID',
};

export const ACCOUNT_SWITCH = 'ACCOUNT_SWITCH';
export const userSelectsAccount = (id, clAppAccountUserGuid) => ({
    type: LA_ACTION.SELECT_ACCOUNT,
    payload: {id, clAppAccountUserGuid},
});

export const resetSelectedClAppAccountUserGuid = () => ({
    type: LA_ACTION.RESET_SELECTED_CL_APP_ACCOUNT_USER_GUID,
});