import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Grid from '@mui/material/Grid';

import { getRoute, routeCodes } from '../../../../constants/routes';
import Option from '../../../../constants/options/dropdownOptions';
import { getAssetDomain } from '../../../../constants/assets';
import TOOLTIP from '../../../../constants/tooltipText';
import SearchValidation from '../SearchValidation';
import Search from '../../../../constants/rapidSearch';
import { isAU } from '../../../../constants/crux';
import MoreActions from '../MoreActions';
import Commons from '../../../../helpers/Commons';
import LocalStorageHelper, { SEARCH_BAR_TOOLTIP_KEY } from '../../../../helpers/LocalStorageHelper';
import listPropertyPreview from '../../../../constants/listPropertyPreview';
import FieldTooltip from '../FieldTooltip';
import { conjunctUrlParams } from '../../../../helpers/search/ParamsBuilder';
import PopulateSearchFields from '../../../../helpers/search/PopulateSearchFields';
import { saveSearchType } from '../../../../actions/componentAccess';
import { cruxLoader, cruxUnloader } from '../../../../actions/crux';
import STATES from '../../../../constants/states';
import ExactSearchCheckbox from './ExactSearchCheckbox';
import ClapiApi from '../../../../api/clapi';
import { cruxAppError } from '../../../../actions/errorHandler';
import ErrorMsg from '../../../../constants/errorMsg';
import { ErrorType } from '../../../../constants/redirect';
import Segment from '../../../../helpers/Segment';
import { trackEvent } from '../../../../actions/segment';
import StyledSearchButton from '../../../common/styledMUI/StyledSearchButton';
import SearchField from '../../../common/textFields/SearchField';
import StateSelect from '../StateSelect';
import saveAndGetUserSetting from '../../../../helpers/userPreferences/saveAndGetUserSetting';
import { SELECTED_STATE_BUILDING } from '../../../../helpers/Localization';

class BuildingSearch extends Component {
    static propTypes = {
        route: PropTypes.object.isRequired,
        dispatch: PropTypes.func,
        showPopper: PropTypes.bool,
        isCheckboxVisible: PropTypes.bool,
        navSearch: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            buildingName: '',
            addressState: '',
            exactSearch: false,
            isBuildingNameFieldError: false,
            buildingNameFieldErrorMessage: '',
            isStateFieldError: false,
            stateFieldErrorMessage: '',
            addressSuburb: '',
            anchorEl: [],
            open: [],
            isViewed: [],
            queryString: '',
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
    }

    componentDidUpdate(prevProps) {
        const { route } = this.props;
        if (route.id !== routeCodes.BUILDING_SEARCH_RESULT.id &&
            prevProps.route !== route) {
            this.clearAllFields();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        this.clearAllFields();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const nextState = {
            isStateFieldError: prevState.isStateFieldError,
            isBuildingNameFieldError: prevState.isBuildingNameFieldError,
        };
        const { location: { search } = {} } = nextProps;

        // Field Validations
        // Revert field to normal state upon receiving valid input
        if (prevState.isBuildingNameFieldError && prevState.buildingName !== '') {
            nextState.isBuildingNameFieldError = false;
        }
        if (prevState.isStateFieldError && prevState.addressState !== '') {
            nextState.isStateFieldError = false;
        }

        const routePath = getRoute(nextProps.match.path);
        let formattedState = {};
        if (search && search !== prevState.queryString &&
            routePath.id === routeCodes.BUILDING_SEARCH_RESULT.id) {
            nextState.queryString = search;
            formattedState = PopulateSearchFields(search);
        }

        return { ...nextState, ...formattedState };
    }

    clearAllFields = () => {
        this.setState({
            buildingName: '',
            addressState: '',
            addressSuburb: '',
        });
    }

    validateBuildingName(buildingName) {
        if (!buildingName || buildingName.trim() === '') {
            this.setState({
                isBuildingNameFieldError: true,
                buildingNameFieldErrorMessage: Search.ERROR_MESSAGE.buildingNameRequired,
            });
        }
    }

    validateAddressState(addressState) {
        // Validate whether addressState field is empty or not
        if (addressState === '') {
            this.setState({
                isStateFieldError: true,
                stateFieldErrorMessage: Search.ERROR_MESSAGE.stateRequired,
            });
        }
    }

    redirectPage = (uri) => {
        const { history, dispatch } = this.props;
        history.push(uri);
        if (uri.includes('property')) {
            this.clearAllFields();
            dispatch(saveSearchType(Option.BUILDING_NAME));
        }
    };

    buildingSearchSubmit = () => {
        const {
            exactSearch, buildingName, addressState, addressSuburb,
        } = this.state;

        const { dispatch } = this.props;

        const params = {
            limit: 1,
            sort: '+address',
            addressBuildingName: {
                value: buildingName,
                exactSearch,
            },
            addressState,
            addressSuburb,
        };

        this.validateBuildingName(buildingName);
        this.validateAddressState(addressState);

        if (buildingName.trim() !== '' && (addressState !== '')) {
            saveAndGetUserSetting(dispatch, {
                name: SELECTED_STATE_BUILDING,
                value: addressState,
            });
            dispatch(cruxLoader());
            let uri = '';
            ClapiApi.getBatchSearch(params).then((result) => {
                const totalElements = Commons.get(result, 'metadata.totalElements');
                const [firstData] = Commons.get(result, 'data');
                if (totalElements === 1) {
                    uri = routeCodes.PROPERTY.path(firstData.addressComplete, firstData.id);
                } else {
                    uri = `${routeCodes.BUILDING_SEARCH_RESULT.path}?${this.constructQueryParams()}`;
                }
                const goToPdp = uri.includes('property');
                this.trackMixpanelEvent(goToPdp);
                this.redirectPage(uri);
            }).catch(() => {
                dispatch(cruxAppError(ErrorMsg.UNAVAILABLE, ErrorType.PAGE_ERROR));
            }).finally(() => {
                dispatch(cruxUnloader());
            });
        }
    }

    trackMixpanelEvent = (goToPdp) => {
        const { dispatch, route: { pageContext } = {}, activeView } = this.props;

        const {
            buildingName,
            addressState,
            addressSuburb,
            exactSearch,
        } = this.state;

        const _addressSuburb = addressSuburb ? `, ${addressSuburb}` : '';
        dispatch(trackEvent(Segment.buildingSearch({
            pageContext,
            exactSearch,
            searchString: `${buildingName}, ${addressState}${_addressSuburb}`,
            activeView: !goToPdp && activeView,
        })));
    };

    constructQueryParams = () => {
        const {
            exactSearch, buildingName,
            addressState, addressSuburb,
        } = this.state;

        const formattedParams = {
            exactSearch,
            buildingName,
            addressState,
            addressSuburb,
        };
        return conjunctUrlParams(formattedParams);
    };

    toggleState = (stateName) => {
        this.setState(prevState => ({
            ...prevState,
            [stateName]: !prevState[stateName],
        }));
    };

    handleFocusPopper = (event, index) => {
        const { currentTarget } = event;
        if (Commons.get(LocalStorageHelper.get(SEARCH_BAR_TOOLTIP_KEY), `is-${listPropertyPreview.SEARCH_TYPE.BUILDING}-${currentTarget.id}-viewed`)) return;
        this.setState(state => ({
            anchorEl: {
                ...state.anchorEl,
                [index]: currentTarget,
            },
            open: {
                ...state.open,
                [index]: true,
            },
        }));
    };

    handleClosePopper = (event, index) => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: {
                ...state.anchorEl,
                [index]: currentTarget,
            },
            open: {
                ...state.open,
                [index]: false,
            },
            isViewed: {
                ...state.isViewed,
                [index]: true,
            },
        }));
    };

    handleClickAway = (event, index) => {
        setTimeout(() => {
            this.setState(state => ({
                open: {
                    ...state.open,
                    [index]: false,
                },
            }));
        }, 150);
    };

    handleScroll = () => {
        this.setState(prevState => ({ open: !prevState.open }));
    };

    handleStateChange = (type) => {
        this.setState({ addressState: type });
    }

    render() {
        const { isCheckboxVisible, location, navSearch } = this.props;
        const {
            isStateFieldError, isBuildingNameFieldError, buildingNameFieldErrorMessage,
            stateFieldErrorMessage, addressState, exactSearch, anchorEl, open, isViewed,
        } = this.state;

        const {
            SUBURB, BUILDING_NAME,
        } = TOOLTIP.TEXT.BUILDING_SEARCH;
        const stateOptions = STATES.reduce((res, keys) =>
            ({ ...res, [keys]: { label: keys } }), {});

        const inputProps = [
            {
                field: 'buildingName',
                placeholder: 'Enter a building name *',
                onChange: event => this.setState({ buildingName: event.target.value }),
                tooltipTxt: BUILDING_NAME,
                validation: {
                    message: buildingNameFieldErrorMessage,
                    onError: isBuildingNameFieldError,
                },
                containerSize: 6,
                placement: 'bottom-start',
            },
            {
                field: 'addressState',
                containerSize: 3,
            },
            {
                field: 'addressSuburb',
                placeholder: 'Enter a Suburb',
                onChange: event => this.setState({ addressSuburb: event.target.value }),
                tooltipTxt: SUBURB,
                containerSize: isAU ? 3 : 6,
                placement: isAU ? 'bottom' : 'bottom-start',
            },
        ];
        return (
            <div className={(!isCheckboxVisible ? 'search-inputs more-actions' : 'search-inputs')}>
                <div className="building-search-primary-fields">
                    <Grid container className="grid-container">
                        {
                            inputProps.map(({
                                field,
                                placeholder,
                                onChange,
                                tooltipTxt,
                                validation,
                                containerSize,
                                placement,
                            }, index) => {
                                if (field !== 'addressState') {
                                    return (
                                        <Grid key={`grid-${index}-${field}`} item className="item" xs={containerSize}>
                                            <div
                                                className={`form-input-search-field ${field}-field ${(validation && validation.onError) && 'error'}`}
                                            >
                                                <SearchField
                                                    value={this.state[field]}
                                                    type="text"
                                                    className="form-control"
                                                    id={field}
                                                    placeholder={placeholder}
                                                    onChange={onChange}
                                                    onFocus={event => this.handleFocusPopper(event, index)}
                                                    autoComplete="off"
                                                    onBlur={event => this.handleClickAway(event, index)}
                                                    error={!!validation?.onError}
                                                    color="primary"
                                                />
                                                <FieldTooltip
                                                    id={field}
                                                    key={`building-search-popper-${field}`}
                                                    className={`${!isCheckboxVisible ? 'search-results' : ''} building-search-tooltip ${navSearch && field === 'addressSuburb' && 'shrink'} ${field}-tooltip ${isAU && 'au-tooltip'}`}
                                                    open={(open[index] && !isViewed[index])}
                                                    anchorEl={anchorEl[index] || null}
                                                    handleClose={event => this.handleClosePopper(event, index)}
                                                    tooltipTxt={tooltipTxt}
                                                    searchType={listPropertyPreview.SEARCH_TYPE.BUILDING}
                                                    placement={placement}
                                                />

                                                {
                                                    validation &&
                                                    <SearchValidation
                                                        message={validation.message}
                                                        onError={validation.onError}
                                                    />
                                                }

                                            </div>
                                        </Grid>
                                    );
                                }
                                return (
                                    <StateSelect
                                        key={`${index}-${field}`}
                                        addressState={addressState}
                                        options={stateOptions}
                                        showToolTip={false}
                                        isStateFieldError={isStateFieldError}
                                        stateFieldErrorMessage={stateFieldErrorMessage}
                                        handleStateChange={this.handleStateChange}
                                        userSettingKey={SELECTED_STATE_BUILDING}
                                    />
                                );
                            })
                        }
                    </Grid>
                    {
                        !isCheckboxVisible &&
                        <MoreActions isChecked={exactSearch}>
                            <ExactSearchCheckbox
                                checked={exactSearch}
                                onChange={() => this.toggleState('exactSearch')}
                            />
                            <p className="tooltipText">{TOOLTIP.TEXT.BUILDING_SEARCH.EXACT_SEARCH}</p>
                        </MoreActions>
                    }
                    <StyledSearchButton
                        data-testid="building-search-button"
                        className="button-primary name-search-btn"
                        onClick={this.buildingSearchSubmit}
                        disabled={location.search === `?${this.constructQueryParams()}`}
                    >
                        <img
                            src={getAssetDomain('search_icon_white.png')}
                            className="search-icon"
                            alt="building search"
                        />
                    </StyledSearchButton>
                </div>
                {
                    isCheckboxVisible &&
                    <div id="advanced-search-options">
                        <ExactSearchCheckbox
                            checked={exactSearch}
                            onChange={() => this.toggleState('exactSearch')}
                        />
                    </div>
                }
            </div>
        );
    }
}

export default connect(state => ({
    activeView: state.searchResults.get('activeView'),
}))(withRouter(BuildingSearch));
