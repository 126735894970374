import { Map as IMap } from 'immutable';
import { REHYDRATE } from 'redux-persist';
import * as TYPE from 'js/actions/clapi';
import Commons from 'js/helpers/Commons';

export const initialState = IMap({
    auth: {},
    isInitialized: false,
    suggestions: [],
    parcelSuggestions: [],
    legalSuggestions: [],
    contacts: {},
    isHouseholdContactFetching: false,
    comparableRule: '',
    advertisement: {},
    recentAdvertisement: {},
    isRecentAdvertisementFetching: false,
    isRecent: false,
    generalDisclaimer: null,
    contentDisclaimer: null,
    valuationDisclaimer: null,
    stateDisclaimer: null,
    censusDisclaimer: null,
    propertyDetailsLocation: null,
    copyRights: null,
    usrDetail: null,
    updatePropertySuccess: false,
    isUpdatePropertyFetching: false,
    propertyPhoto: {},
    titleDescription: {},
});

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case TYPE.CLAPI_GET_AUTH:
            return state.merge(IMap({
                isInitialized: true,
            }));
        case TYPE.CLAPI_GET_AUTH_FAIL:
            return state.merge(IMap({
                auth: {
                    hasFailed: true,
                },
            }));
        case TYPE.CLAPI_GET_AUTH_SUCCESS:
            return state.merge(IMap({
                auth: action.payload,
            }));
        case TYPE.CLAPI_CLEAR_PROPERTY:
        case TYPE.GET_PARTIAL_PROPERTY_DETAILS:
            return state.merge(IMap({
                contacts: {},
                advertisement: {},
                recentAdvertisement: {},
                recentSold: [],
                forSale: [],
                forRent: [],
                comparableRule: '',
                avmDetails: {
                    isFetchingAvm: true,
                },
                stateDisclaimer: null,
            }));
        case TYPE.GET_TITLE_DESCRIPTION_SUCCESS:
            return state.merge(IMap({
                titleDescription: action.payload,
            }));
        case TYPE.GET_TITLE_DESCRIPTION_FAIL:
            return state.merge(IMap({
                titleDescription: {},
            }));
        case TYPE.GET_SUGGESTIONS_SUCCESS:
            return state.merge(IMap({
                isRecent: false,
                suggestions: action.payload.suggestions || [],
            }));
        case TYPE.HANDLE_RECENT_SEARCH_SUCCESS:
            return state.merge(IMap({
                isRecent: true,
                suggestions: action.payload.suggestions || [],
            }));
        case TYPE.GET_PARCEL_SUGGESTIONS_SUCCESS:
            return state.merge(IMap({
                parcelSuggestions: action.payload.suggestions || [],
            }));
        case TYPE.GET_LEGAL_SUGGESTIONS_SUCCESS:
            return state.merge(IMap({
                legalSuggestions: action.payload.suggestions || [],
            }));
        case TYPE.GET_TITLE_REF_SUGGESTIONS_SUCCESS:
            return state.merge(IMap({
                titleRefSuggestions: action.payload.suggestions || [],
            }));
        case TYPE.CLAPI_GET_RENTAL_AVM:
            return state.merge(IMap({
                rentalAvm: {
                    isLoading: true,
                },
            }));
        case TYPE.CLAPI_GET_RENTAL_AVM_FAIL:
            return state.merge(IMap({
                rentalAvm: { isLoading: false, isError: true },
            }));
        case TYPE.CLAPI_GET_RENTAL_AVM_SUCCESS:
            return state.merge(IMap({
                rentalAvm: {
                    isLoading: false,
                    ...action.payload.rentalAvmDetail,
                },
            }));
        case TYPE.CLAPI_GET_INTELLIVAL_VALUATION_AVM_FAIL:
            return state.merge(IMap({
                avmDetails: { isFetchingAvm: false, isError: true },
            }));
        case TYPE.CLAPI_GET_INTELLIVAL_VALUATION_AVM_SUCCESS:
            return state.merge(IMap({
                avmDetails: { ...action.payload, isFetchingAvm: false },
            }));
        case TYPE.CLEAR_SUGGESTION:
            return state.merge(IMap({
                isRecent: false,
                suggestions: [],
            }));
        case TYPE.CLEAR_PARCEL_SUGGESTION:
            return state.merge(IMap({
                parcelSuggestions: [],
            }));
        case TYPE.CLEAR_LEGAL_SUGGESTION:
            return state.merge(IMap({
                legalSuggestions: [],
            }));
        case TYPE.CLEAR_TITLE_REF_SUGGESTION:
            return state.merge(IMap({
                titleRefSuggestions: [],
            }));
        case TYPE.GET_HOUSEHOLD_CONTACTS:
            return state.merge(IMap({
                isHouseholdContactFetching: true,
            }));
        case TYPE.GET_HOUSEHOLD_CONTACTS_SUCCESS:
            return state.merge(IMap({
                contacts: action.payload,
                isHouseholdContactFetching: false,
            }));
        case TYPE.GET_HOUSEHOLD_CONTACTS_FAIL:
            return state.merge(IMap({
                isHouseholdContactFetching: false,
            }));
        case TYPE.GET_LATEST_ADVERTISEMENT_DESC:
            return state.merge(IMap({
                isRecentAdvertisementFetching: true,
            }));
        case TYPE.GET_LATEST_ADVERTISEMENT_DESC_FAIL:
            return state.merge(IMap({
                isRecentAdvertisementFetching: false,
                isError: true,
            }));
        case TYPE.GET_LATEST_ADVERTISEMENT_DESC_SUCCESS:
            return state.merge(IMap({
                recentAdvertisement: action.payload,
                isRecentAdvertisementFetching: false,
                isError: false,
            }));
        case TYPE.GET_PROPERTY_FOR_SALE_ADVERTISEMENT_SUCCESS:
            return state.merge(IMap({
                advertisement: { ...state.get('advertisement'), advertisementSaleDesc: action.payload.forSaleAdvertisementList },
            }));
        case TYPE.GET_PROPERTY_FOR_RENT_ADVERTISEMENT_SUCCESS:
            return state.merge(IMap({
                advertisement: { ...state.get('advertisement'), advertisementRentDesc: action.payload.forRentAdvertisementList },
            }));
        case TYPE.SET_COMPARABLE_RULE:
            return state.merge(IMap({
                comparableRule: action.payload,
            }));
        case TYPE.GET_PROPERTY_PROFILE_SUCCESS:
        case TYPE.GET_USER_DETAIL_SUCCESS:
            return state.merge(IMap({
                usrDetail: action.payload.usrDetail,
            }));
        case TYPE.GET_GENERAL_DISCLAIMER:
            return state.merge(IMap({
                generalDisclaimer: { isFetching: true },
            }));
        case TYPE.GET_GENERAL_DISCLAIMER_SUCCESS:
            return state.merge(IMap({
                generalDisclaimer: {
                    disclaimer: action.payload,
                    isFetching: false,
                },
            }));
        case TYPE.GET_GENERAL_DISCLAIMER_FAIL:
            return state.merge(IMap({
                generalDisclaimer: { isFetching: false },
            }));
        case TYPE.GET_CONTENT_DISCLAIMER_SUCCESS:
            return state.merge(IMap({
                contentDisclaimer: {
                    disclaimer: action.payload,
                },
            }));
        case TYPE.GET_VALUATION_DISCLAIMER_SUCCESS:
            return state.merge(IMap({
                valuationDisclaimer: {
                    disclaimer: action.payload,
                },
            }));
        case TYPE.GET_STATE_DISCLAIMER_SUCCESS:
            return state.merge(IMap({
                stateDisclaimer: {
                    disclaimer: action.payload,
                    isFetching: false,
                },
            }));
        case TYPE.GET_STATE_DISCLAIMER:
            return state.merge(IMap({
                stateDisclaimer: { isFetching: true },
            }));
        case TYPE.GET_STATE_DISCLAIMER_FAIL:
            return state.merge(IMap({
                stateDisclaimer: { isFetching: false },
            }));
        case TYPE.GET_CENSUS_DISCLAIMER:
            return state.merge(IMap({
                censusDisclaimer: { isFetching: true },
            }));
        case TYPE.GET_CENSUS_DISCLAIMER_SUCCESS:
            return state.merge(IMap({
                censusDisclaimer: {
                    disclaimer: action.payload,
                    isFetching: false,
                },
            }));
        case TYPE.GET_CENSUS_DISCLAIMER_FAIL:
            return state.merge(IMap({
                censusDisclaimer: { isFetching: false },
            }));
        case TYPE.GET_PROPERTY_DETAILS_LOCATION:
        case TYPE.GET_PROPERTY_DETAILS_LOCATION_FAIL:
            return state.merge(IMap({
                propertyDetailsLocation: null,
                stateDisclaimer: null,
            }));
        case TYPE.GET_PROPERTY_DETAILS_LOCATION_SUCCESS:
            return state.merge(IMap({
                propertyDetailsLocation: action.payload,
            }));
        case TYPE.GET_COPYRIGHTS_SUCCESS:
            return state.merge(IMap({
                copyRights: {
                    disclaimer: action.payload,
                },
            }));
        case TYPE.UPDATE_USER_PHOTO: {
            const usrDetail = state.get('usrDetail') || {};
            const userAccountDetail = Commons.get(usrDetail, 'userAccountDetail') || {};
            return state.merge(IMap({
                usrDetail: {
                    ...usrDetail,
                    userAccountDetail: {
                        ...userAccountDetail,
                        profilePhoto: action.payload,
                    },
                },
            }));
        }
        case TYPE.SAVE_PROPERTY_PHOTO:
            return state.merge(IMap({
                propertyPhoto: action.payload,
            }));
        case REHYDRATE:
            if (action.payload) {
                return state.merge(action.payload.clapi);
            }
            return state.merge(initialState);
        default:
            return state;
    }
}
